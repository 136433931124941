import { LoadingState } from '../AdminHome/components/LoadingState';
import { useNavigate } from 'react-router-dom';
import { useIsAllowedToAccessAdminHome } from '../AdminHome/hooks/is-allowed-to-access-admin-home';

export const Home = () => {
  const navigate = useNavigate();
  const isAllowedToAdmin = useIsAllowedToAccessAdminHome();
  const path = isAllowedToAdmin ? '/home/admin' : '/home/employee';

  navigate(path);

  return <LoadingState />;
};
