import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import {
  getAuthenticatedUser,
  getFromLS,
  setInLS,
  useLegacyPermissions,
  useSelectedCompany,
} from '@flash-tecnologia/hros-web-utility';
import { useFlag } from '@flash-tecnologia/feature-flags';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { setEventTracking } from '@utils';

import {
  MainPage,
  OnboardingTasksContainer,
  PageHeader,
  RowPageContainer,
  PageContainer,
  SectionBlocks,
} from './admin-home.styles';
import { useEffect, useMemo, useState } from 'react';
import { useCompanyMigrated } from '@flash-hros/hocs';
import { PermissionsBanner } from './components/PermissionsBanner';
import { OnboardingSteps } from './components/OnboardingSteps';
import {
  FLASH_OS_HOME_PROMOTIONAL_BANNER,
  PromotionalBanner,
} from './components/PromotionalBanner';
import { Product } from './components/Product';
import { OnboardingProvider } from '@context/onboarding';
import { QuickAccessSection } from './components/QuickAccessSection';
import { ContractModal, EmailConfirmModal, OnboardingModal } from '@components';
import { usePageTracking } from '@/hooks';
import AlertSection from '@/components/AlertSection';
import { useIsAllowedToAccessAdminHome } from './hooks/is-allowed-to-access-admin-home';
import { TaskWidget } from './components/QuickAccessSection/components/TaskWidget';

export const AdminHome = () => {
  const [t] = useTranslation('translations', { keyPrefix: 'adminHomePage' });
  const navigate = useNavigate();
  const { selectedCompany } = useSelectedCompany();
  const isAllowedToAccess = useIsAllowedToAccessAdminHome();
  const [searchParams, setSearchParams] = useSearchParams();
  const showOnboardingParam = searchParams.get('onboarding');
  const isPromotionalBannerEnabled = useFlag({
    flagName: FLASH_OS_HOME_PROMOTIONAL_BANNER,
  });

  const [username, setUsername] = useState('');

  const { isMigrated, loading: loadingCompany } = useCompanyMigrated();
  const { checkSectionPermission, permissions } = useLegacyPermissions();
  const hasBenefits = useMemo(() => {
    return checkSectionPermission('flash_benefits');
  }, [permissions]);

  const userOnboardingSteps = getFromLS('userOnboardingSteps');

  const [onboardingModalOpen, setOnboardingModalOpen] = useState(false);
  const [isOnboardingStepsOpen, setIsOnboardingStepsOpen] = useState(
    userOnboardingSteps?.isFixedOnHome ?? true,
  );

  usePageTracking('home_screen', {
    view: 'admin',
    companyId: selectedCompany?.id,
    companyName: selectedCompany?.name,
  });

  useEffect(() => {
    if (!isAllowedToAccess) {
      navigate('/home/employee');
    }

    const getUsername = async () => {
      const user = await getAuthenticatedUser();
      setUsername(user?.name ?? '');
    };

    getUsername();

    const pinOnboardingHandler = () => {
      setIsOnboardingStepsOpen(true);
      setInLS({
        key: 'userOnboardingSteps',
        value: { ...userOnboardingSteps, isFixedOnHome: true },
      });
    };

    window.addEventListener('pinOnboardingStepsOnHome', pinOnboardingHandler);

    return () => {
      window.removeEventListener(
        'pinOnboardingStepsOnHome',
        pinOnboardingHandler,
      );
    };
  }, []);

  useEffect(() => {
    if (showOnboardingParam === 'true' && !loadingCompany) {
      setOnboardingModalOpen(true);
      searchParams.delete('onboarding');
      setSearchParams(searchParams);
    }
  }, [showOnboardingParam, loadingCompany]);

  const onOnboardingClose = () => {
    setIsOnboardingStepsOpen(false);
    setInLS({
      key: 'userOnboardingSteps',
      value: { ...userOnboardingSteps, isFixedOnHome: false },
    });
  };

  const onPeopleProductClick = () => {
    setEventTracking('home_people_product_know_more_clicked');
    navigate('/flows/hiring');
  };

  const showBannerOrSetupSection =
    isPromotionalBannerEnabled || (isOnboardingStepsOpen && hasBenefits);

  return (
    <OnboardingProvider>
      <>
        <AlertSection />
        <MainPage>
          <PageHeader
            title={`${t('welcome')}${username ? `, ${username}!` : '!'}`}
          />
          <PermissionsBanner />
          {showBannerOrSetupSection && (
            <RowPageContainer>
              {isPromotionalBannerEnabled && <PromotionalBanner />}
              {isOnboardingStepsOpen && hasBenefits && (
                <OnboardingTasksContainer>
                  <OnboardingSteps onClose={onOnboardingClose} />
                </OnboardingTasksContainer>
              )}
            </RowPageContainer>
          )}
          {/* Temporary while quick access for others BUs doesn't exist among its permissions */}
          {hasBenefits && (
            <PageContainer>
              <QuickAccessSection />
            </PageContainer>
          )}
          <PageContainer>
            <TaskWidget />
          </PageContainer>
          <PageContainer>
            <Typography variant="headline7" style={{ marginBottom: '20px' }}>
              {t('productsSectionTitle')}
            </Typography>
            <SectionBlocks>
              <Product
                icon="IconUsers"
                title={t('peopleProduct.title')}
                text={t('peopleProduct.description')}
                topics={[
                  ['IconUserPlus', t('peopleProduct.admissionTopic')],
                  ['IconMessageDots', t('peopleProduct.trainingTopic')],
                  ['IconRocket', t('peopleProduct.performanceTopic')],
                ]}
                actionText={t('peopleProduct.actionText')}
                onClick={onPeopleProductClick}
              />
            </SectionBlocks>
          </PageContainer>
        </MainPage>
        <EmailConfirmModal />
        <OnboardingModal
          open={onboardingModalOpen}
          username={username}
          showMigrationTexts={isMigrated}
          onClose={() => setOnboardingModalOpen(false)}
        />
        <ContractModal />
      </>
    </OnboardingProvider>
  );
};
