import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useUpdateFlagsContext } from '@flash-tecnologia/feature-flags';
import { RoutesGuard } from './routesGuard';
import { useSession } from '@/hooks';
import { useEffect } from 'react';
import { AdminHome, EmployeeHome, Home } from '@/pages';

const AppRouter = () => {
  const { userId, companyId, economicGroupId } = useSession();
  const updateContext = useUpdateFlagsContext();

  useEffect(() => {
    updateContext(userId, {
      economicGroupId,
      companyId,
      employeeId: userId,
    });
  }, [userId, companyId]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="home">
          <Route path="admin" element={<AdminHome />} />
          <Route path="employee" element={<EmployeeHome />} />
          <Route index element={<Home />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
