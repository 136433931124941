export default {
  translations: {
    homePage: {
      home: 'Home',
      contentTitle: "You've just made a choice that makes all others easier",
      subTitle:
        'Take the opportunity to get to know the platform and enjoy the freedom that only Flash gives you 🦩⚡',
    },
  },
};
