import { Loader, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import {
  Container,
  LoadingContainer,
  TextContainer,
} from './loading-state.styles';
import { useEffect, useState } from 'react';

export const LoadingState = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const loadingTexts = [
    'Carregando os seus produtos...',
    'Simplificando a sua rotina...',
    'Tornando sua experiência mais rosa...',
    'Trazendo liberdade para dentro da carteira...',
    'Carregando as melhores ferramentas...',
    'Organizando os produtos mais queridos do RH...',
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % loadingTexts.length);
    }, 30 * 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Container>
      <LoadingContainer>
        <Loader size="large" variant="primary" />
        <TextContainer>
          <Typography
            variant="headline6"
            variantColor="var(--color-secondary-50)"
          >
            Aguarde um momento, estamos organizando tudo para sua experiência
            Flash.
          </Typography>
          <Typography variant="body3">{loadingTexts[currentIndex]}</Typography>
        </TextContainer>
      </LoadingContainer>
    </Container>
  );
};
