import { Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { Container } from './styles';
import { useTranslation } from 'react-i18next';

interface AddQuickAccessItemProps {
  onClick: () => void;
}

export const AddQuickAccessItem = ({ onClick }: AddQuickAccessItemProps) => {
  const [t] = useTranslation('translations', { keyPrefix: 'quickAccess' });
  return (
    <Container onClick={onClick}>
      <Icons name="IconPlus" size={16} color="var(--color-secondary-50)" />
      <Typography variant="caption" variantColor="var(--color-secondary-50)">
        {t('addQuickAccessButton')}
      </Typography>
    </Container>
  );
};
