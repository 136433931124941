import { Dot, Icons, iconsOptions } from '@flash-tecnologia/hros-web-ui-v2';
import {
  StyledChevronIcon,
  StyledCollapseWrapper,
  StyledDescription,
  StyledHeaderWrapper,
  StyledTaskGroupAccordion,
  StyledTaskGroupAccordionWrapper,
  StyledTaskItemLink,
  StyledTaskList,
  StyledTaskListActionWrapper,
  StyledTitle,
  StyledTitleWrapper,
} from './styles';
import { useTheme } from 'styled-components';
import { ReactNode, useCallback, useState } from 'react';
import { Task as TaskType } from '../../types';
import { Task } from '../Task';
import { formatExpiration } from '../../utils';
import { Tag } from '../Tag';

type TaskGroupProps = {
  title: string;
  description: string;
  icon: typeof iconsOptions[number];
  rightIcon?: ReactNode;
  tasks: TaskType[];
};

export const TaskGroup = ({
  title,
  description,
  icon,
  rightIcon,
  tasks,
}: TaskGroupProps) => {
  const theme = useTheme();
  const [opened, setOpened] = useState(false);
  const [numberOfTasksShown, setNumberOfTasksShown] = useState(5);
  const tasksToShow = tasks.slice(0, numberOfTasksShown);

  const getTagContent = useCallback((dueDate: Date | null) => {
    if (!dueDate) return null;

    const now = Date.now();
    const dateTime = dueDate.getTime();

    const text = formatExpiration(dueDate);
    const isExpired = now > dateTime;

    return (
      <Tag
        text={text}
        type={isExpired ? 'WARNING' : 'NEUTRAL'}
        leftIcon={<Dot variant={isExpired ? 'yellow' : 'gray'} />}
      />
    );
  }, []);

  return (
    <StyledTaskGroupAccordionWrapper>
      <StyledTaskGroupAccordion
        opened={opened}
        onClick={() => setOpened((isOpened) => !isOpened)}
      >
        <StyledCollapseWrapper>
          <StyledChevronIcon
            opened={opened}
            name="IconChevronDown"
            size={20}
            color={theme.colors.neutral[40]}
          />
          <StyledHeaderWrapper>
            <StyledTitleWrapper>
              <Icons name={icon} size={14} color={theme.colors.neutral[30]} />
              <StyledTitle variant="headline10">{title}</StyledTitle>
            </StyledTitleWrapper>
            <StyledDescription variant="caption" weight={600}>
              {description}
            </StyledDescription>
          </StyledHeaderWrapper>
        </StyledCollapseWrapper>
        {rightIcon ? rightIcon : null}
      </StyledTaskGroupAccordion>
      {opened && (
        <StyledTaskList>
          {tasksToShow.map((task, index) => (
            <Task
              tags={getTagContent(task.dueDate)}
              index={index}
              title={task.title}
              description={task.description}
              path={task.redirectPath}
              key={task.id}
              style={{
                borderBottom:
                  tasks.length <= numberOfTasksShown &&
                  index !== tasksToShow.length - 1,
                radiusBottom:
                  tasks.length <= numberOfTasksShown &&
                  index === tasksToShow.length - 1,
              }}
              btnText="Analisar"
            />
          ))}
          {tasks.length > numberOfTasksShown && (
            <StyledTaskListActionWrapper
              onClick={() => {
                setNumberOfTasksShown((oldValue) => oldValue + 5);
              }}
            >
              <StyledTaskItemLink variant="body4" weight={700}>
                Ver todas as tarefas
              </StyledTaskItemLink>
              <Icons
                name="IconChevronRight"
                size={18}
                color={theme.colors.secondary[50]}
              />
            </StyledTaskListActionWrapper>
          )}
        </StyledTaskList>
      )}
    </StyledTaskGroupAccordionWrapper>
  );
};
