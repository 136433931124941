import { RadioCard } from '@components/RadioCard';
import { RadioBox, TextBox } from './styles';

type PermissionsOptionsProps = {
  redirectToPermissions: boolean;
  changeRedirectToPermission: (option: boolean) => void;
};

const PermissionsOptions = ({
  changeRedirectToPermission,
  redirectToPermissions,
}: PermissionsOptionsProps) => (
  <RadioBox>
    <RadioCard.Root
      checked={redirectToPermissions === true}
      onClick={() => changeRedirectToPermission(true)}
    >
      <RadioCard.Radio
        checked={redirectToPermissions === true}
        onChange={() => {}}
      />
      <TextBox>
        <RadioCard.Text
          checked={redirectToPermissions === true}
          text={'Atribuir acessos e permissões agora (recomendado)'}
          textVariant={'body3'}
        />
        <RadioCard.Text
          checked={redirectToPermissions === true}
          text={
            'Crie perfis e conceda permissões de administrador a outras pessoas da empresa'
          }
          textVariant={'body4'}
        />
      </TextBox>
    </RadioCard.Root>
    <RadioCard.Root
      checked={redirectToPermissions === false}
      onClick={() => changeRedirectToPermission(false)}
    >
      <RadioCard.Radio
        checked={redirectToPermissions === false}
        onChange={() => {}}
      />
      <TextBox>
        <RadioCard.Text
          checked={redirectToPermissions === false}
          text={'Fazer isso mais tarde'}
          textVariant={'body3'}
        />
      </TextBox>
    </RadioCard.Root>
  </RadioBox>
);

export default PermissionsOptions;
