import { ReactNode, useState } from 'react';
import {
  StyledContent,
  StyledTab,
  StyledTabIndicator,
  StyledTabs,
  StyledTabsContainer,
  StyledTabsWrapper,
} from './style';

type TabsProps = {
  tabs: {
    title: ReactNode;
    content: ReactNode;
  }[];
};

export const Tabs = ({ tabs }: TabsProps) => {
  const [active, setActive] = useState(0);

  return (
    <StyledTabsWrapper>
      <StyledTabsContainer>
        <StyledTabs>
          {tabs.map((tab, index) => (
            <StyledTab
              active={index === active}
              key={index}
              onClick={() => setActive(index)}
            >
              {tab.title}
            </StyledTab>
          ))}
        </StyledTabs>
        <StyledTabIndicator activeIndex={active} />
      </StyledTabsContainer>
      <StyledContent>{tabs[active].content}</StyledContent>
    </StyledTabsWrapper>
  );
};
