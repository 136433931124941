import { ReactNode } from 'react';
import { StyledTag, StyledTagLabel } from './styles';

export type TYPES = 'WARNING' | 'SUCCESS' | 'DEFAULT' | 'NEUTRAL';

type TagProps = {
  text: string;
  type: TYPES;
  leftIcon?: ReactNode;
};

export const Tag = ({ text, leftIcon, type }: TagProps) => {
  return (
    <StyledTag type={type}>
      {leftIcon ? leftIcon : null}
      <StyledTagLabel type={type} variant="caption" weight={600}>
        {text}
      </StyledTagLabel>
    </StyledTag>
  );
};
