import {
  Icons,
  IconsProps,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import { Container, ProductText, TextContainer } from './styles';

export interface QuickAccess {
  key: string;
  icon: IconsProps['name'];
  title: string;
  url: string;
  product?: string;
}

interface QuickAccessItemProps extends Omit<QuickAccess, 'url'> {
  onClick: () => void;
}

export const QuickAccessItem = ({
  icon,
  title,
  onClick,
  product,
}: QuickAccessItemProps) => {
  return (
    <Container onClick={onClick}>
      <Icons name={icon} size={16} color="var(--color-secondary-50)" />
      <TextContainer>
        <Typography variant="caption" variantColor="var(--color-neutral-50)">
          {title}
        </Typography>
        {product && (
          <ProductText
            variant="caption"
            variantColor="var(--color-neutral-50)"
            weight={600}
          >
            {product}
          </ProductText>
        )}
      </TextContainer>
    </Container>
  );
};
