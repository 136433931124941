import { z } from 'zod';

const envSchema = z.object({
  UNLEASH_BENEFITS_URL: z.string(),
  UNLEASH_BENEFITS_PROXY_KEY: z.string(),
  STAGE: z.string(),
});

const env = envSchema.parse(process.env);

export { env };
