import AppRouter from './routes';
import { ThemeProvider } from '@flash-tecnologia/hros-web-ui-v2';
import { QueryClientProvider } from '@tanstack/react-query';

import { trpc, trpcClient, queryClient } from './api/client';
import { ConfigurationContext } from './context/configuration';

import '../src/i18n';
import { FlagsProvider } from '@flash-tecnologia/feature-flags';
import { env } from './lib/env';
import { useSession } from './hooks';

const APP_NAME = 'hros-web-home';

export default function Root() {
  const { companyId, userId, economicGroupId } = useSession();
  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <FlagsProvider
          url={env.UNLEASH_BENEFITS_URL}
          appName={APP_NAME}
          token={env.UNLEASH_BENEFITS_PROXY_KEY}
          refreshIntervalSeconds={1800}
          initialContext={{
            userId,
            properties: {
              economicGroupId,
              companyId,
              employeeId: userId,
            },
          }}
        >
          <ConfigurationContext>
            <ThemeProvider>
              <AppRouter />
            </ThemeProvider>
          </ConfigurationContext>
        </FlagsProvider>
      </QueryClientProvider>
    </trpc.Provider>
  );
}
