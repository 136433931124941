import {
  Icons,
  LinkButton,
  ShapeIcon,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import { BaseModal } from '../../BaseModal';
import {
  FaqContainer,
  SpacedContainer,
  StyledPDFViewer,
} from '../contract-modal.styles';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

interface AmendmentFlowProps {
  open: boolean;
  loading: boolean;
  pdfUrl: string;
  onSign: () => void;
  onClose: () => void;
}

type Steps = 'intro' | 'contract' | 'finish';

export const AmendmentFlow = ({
  open,
  loading,
  pdfUrl,
  onSign,
  onClose,
}: AmendmentFlowProps) => {
  const [t] = useTranslation('translations', {
    keyPrefix: 'contractModal',
  });

  const [step, setStep] = useState<Steps>('intro');

  const onNext = async () => {
    if (step === 'intro') {
      setStep('contract');
      return;
    }

    if (step === 'contract') {
      await onSign();
      setStep('finish');
      return;
    }

    onClose();
  };

  const onBack = () => {
    setStep('intro');
  };

  const nextText = {
    intro: 'Continuar',
    contract: 'Declaro que li e aceito',
    finish: 'Concluir',
  }[step];

  return (
    <BaseModal
      open={open}
      onNext={onNext}
      onNextText={
        <>
          {nextText}
          <Icons name={step === 'intro' ? 'IconArrowRight' : 'IconCheck'} />
        </>
      }
      onBack={step === 'contract' ? onBack : undefined}
      loading={loading}
    >
      {step === 'intro' && (
        <>
          <SpacedContainer>
            <ShapeIcon
              name="IconWritingSign"
              variant="default"
              size={48}
              color="var(--color-secondary-50)"
            />
            <Typography variant="headline8">
              Atualizamos nossos termos de uso! <br />
              Revise e aceite para continuar.
            </Typography>
            <Typography variant="body4" variantColor="var(--color-neutral-40)">
              Gostaríamos de informar que nossos termos de uso foram atualizados
              devido à transição para a Visa.
            </Typography>
          </SpacedContainer>
          <Typography variant="body4" variantColor="var(--color-neutral-40)">
            Essa mudança visa clarificar a relação da Flash com o novo parceiro
            e não trará impactos em sua experiência. Continuamos comprometidos
            em oferecer o mesmo padrão de excelência de sempre!
          </Typography>
        </>
      )}
      {step === 'contract' && (
        <>
          <SpacedContainer>
            <Typography variant="headline8">
              Contrato de Prestação de Serviços
            </Typography>
            <Typography variant="body4" variantColor="var(--color-neutral-40)">
              Leia atentamente e aceite as condições comerciais do Contrato de
              Prestação de Serviços da Flash. O uso da plataforma só será
              liberado após o aceite.
            </Typography>
          </SpacedContainer>
          <Typography
            variant="body4"
            weight={700}
            variantColor="var(--color-neutral-40)"
          >
            {t('indicationText')}
          </Typography>
          <StyledPDFViewer
            width="100%"
            height="280px"
            src={pdfUrl}
            options={{ showToolbar: true, fit: 'height', page: 1 }}
          />
          <FaqContainer>
            <ShapeIcon
              name="IconMessageCircle2"
              variant="default"
              size={32}
              color="var(--color-secondary-50)"
              style={{ flexShrink: 0 }}
            />
            <Typography
              variant="caption"
              weight={700}
              variantColor="var(--color-neutral-30)"
            >
              Possui alguma dúvida?
            </Typography>
            <LinkButton
              variant="primary"
              style={{ fontSize: 12, alignSelf: 'center' }}
            >
              Acesse nosso FAQ
            </LinkButton>
          </FaqContainer>
        </>
      )}
      {step === 'finish' && (
        <SpacedContainer>
          <ShapeIcon
            name="IconCircleCheck"
            variant="success"
            size={48}
            color="var(--color-feedback-success-40)"
          />
          <Typography
            variant="body4"
            weight={700}
            variantColor="var(--color-feedback-success-40)"
          >
            Tudo certo!
          </Typography>
          <Typography variant="headline8">
            Aceite de termos realizado com sucesso!
          </Typography>
          <Typography variant="body4" variantColor="var(--color-neutral-40)">
            Caso tenha qualquer dúvida, é só entrar em contato com nosso time de
            atendimento.
          </Typography>
        </SpacedContainer>
      )}
    </BaseModal>
  );
};
