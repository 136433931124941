import {
  EmptyStateSubtitle,
  EmptyStateTitle,
  EmptyStateTitleWrapper,
  EmptyStateWrapper,
} from './styles';
import { ReactNode } from 'react';

type EmptyStateProps = {
  title: string;
  subtitle: string;
  icon: ReactNode;
};

export const EmptyState = ({ title, subtitle, icon }: EmptyStateProps) => {
  return (
    <EmptyStateWrapper>
      {icon}
      <EmptyStateTitleWrapper>
        <EmptyStateTitle variant="headline8" weight={700}>
          {title}
        </EmptyStateTitle>
        <EmptyStateSubtitle variant="body3" weight={400}>
          {subtitle}
        </EmptyStateSubtitle>
      </EmptyStateTitleWrapper>
    </EmptyStateWrapper>
  );
};
