import styled from 'styled-components';
import { Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2';

const MainPage = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Container = styled.div`
  display: flex;
  border: 1px solid #ebe6e9;
  border-radius: 8px;
  flex-direction: column;
  align-self: center;
  position: relative;
`;

const AbsoluteIcon = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: center;
`;

const StyledIcon = styled(Icons)`
  color: ${({ theme }) => theme.colors.primary};

  @media screen and (max-width: 320px) {
    svg {
      width: 45px;
      height: 45px;
    }
  }
`;

const StyledIconContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.secondary.light.light2};
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-top: -43px;

  @media screen and (max-width: 320px) {
    width: 80px;
    height: 80px;
    margin-top: -32px;
  }
`;

const TextsContainer = styled.div`
  max-width: 384px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 100px 131.5px 52px 131.5px;

  @media screen and (max-width: 768px) {
    margin: 100px 40.5px 52px 40.5px;
  }

  @media screen and (max-width: 368px) {
    margin: 100px 20px 52px 20px;
    font-size: 10px;
  }
`;

const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary.dark.dark5};
  font-weight: 700;

  @media screen and (max-width: 368px) {
    font-size: 20px !important;
    line-height: 24px !important;
  }
`;

const SubTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark4};
  max-width: 330px;
  margin-top: 20px;

  @media screen and (max-width: 368px) {
    font-size: 14px !important;
    line-height: 16px !important;
  }
`;

export {
  MainPage,
  Container,
  AbsoluteIcon,
  StyledIcon,
  StyledIconContainer,
  TextsContainer,
  Title,
  SubTitle,
};
