import styled from 'styled-components';

const Container = styled.div<{
  backgroundColor?: string;
  borderColor?: string;
}>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 100%;
  background-color: ${({ backgroundColor }) =>
    backgroundColor || 'var(--color-neutral-pure)'};
  border: ${({ backgroundColor, borderColor }) =>
    `1px solid ${
      borderColor || backgroundColor || 'var(--color-neutral-pure)'
    }`};
`;

export { Container };
