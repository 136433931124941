import {
  IconTypes,
  Icons,
  LinkButton,
  ShapeIcon,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import { Container, Section, StyledTypography, Topic } from './styles';

type Topic = [iconName: IconTypes, text: string];

interface ProductProps {
  title: string;
  text: string;
  icon: IconTypes;
  topics: Topic[];
  actionText: string;
  onClick: () => void;
}

export const Product = ({
  title,
  text,
  icon,
  topics,
  actionText,
  onClick,
}: ProductProps) => {
  return (
    <Container>
      <Section>
        <ShapeIcon
          name={icon}
          variant="default"
          color="var(--color-primary)"
          size={40}
        />
        <Typography variant="headline8">{title}</Typography>
        <StyledTypography variant="body4">{text}</StyledTypography>
      </Section>
      <Section>
        {topics.map(([iconName, text], i) => (
          <Topic key={i}>
            <Icons
              name={iconName}
              color="var(--color-primary)"
              fill="none"
              size={16}
            />
            <StyledTypography variant="body4">{text}</StyledTypography>
          </Topic>
        ))}
      </Section>
      <LinkButton variant="primary" onClick={onClick}>
        {actionText}
      </LinkButton>
    </Container>
  );
};
