import { useState, useEffect, useCallback, useMemo } from 'react';

type CountdownProps = {
  time: number;
  onFinished?: () => any;
};

export const Countdown = ({ time, onFinished }: CountdownProps) => {
  const [counter, setCounter] = useState<number>(time);

  useEffect(() => {
    const time =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    counter === 0 && onFinished && onFinished();
    return () => clearInterval(time as any);
  }, [counter]);

  const secondsToTime = useCallback(() => {
    let divisor_for_minutes = counter % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    return { m: minutes, s: seconds };
  }, [counter]);

  const timer = useMemo(() => secondsToTime(), [secondsToTime]);

  return (
    <span>
      {timer.m < 10 ? `0${timer.m}` : timer.m}:
      {timer.s < 10 ? `0${timer.s}` : timer.s}
    </span>
  );
};
