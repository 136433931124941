import { Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { Container } from './styles';
import { QuickAccess } from '../QuickAccessItem';
import { Draggable } from 'react-beautiful-dnd';

interface DraggableListItemProps {
  accessItem: QuickAccess;
  index: number;
  onRemove: () => void;
}

export const DraggableListItem = ({
  accessItem,
  onRemove,
  index,
}: DraggableListItemProps) => {
  const getDraggingColor = (isDragging: boolean, color: string) => {
    return isDragging ? 'var(--color-neutral-100)' : color;
  };

  return (
    <Draggable draggableId={accessItem.key} index={index}>
      {(provided, snapshot) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <Container isDragging={snapshot.isDragging}>
            <Icons
              name="IconGripVertical"
              size={24}
              color={getDraggingColor(
                snapshot.isDragging,
                'var(--color-neutral-80)',
              )}
            />
            <Typography
              variant="body4"
              weight={600}
              variantColor={getDraggingColor(
                snapshot.isDragging,
                'var(--color-neutral-50)',
              )}
              style={{ flexGrow: 1 }}
            >
              {accessItem.title}
            </Typography>
            <Icons
              name="IconTrash"
              size={24}
              color={getDraggingColor(
                snapshot.isDragging,
                'var(--color-neutral-50)',
              )}
              onClick={onRemove}
              style={{ cursor: 'pointer' }}
            />
          </Container>
        </div>
      )}
    </Draggable>
  );
};
