import { Icons } from '@flash-tecnologia/hros-web-ui-v2';
import { MainContainer, Container, Number } from './styles';
import { MouseEventHandler, useMemo, useState } from 'react';
import { TaskFeedDrawer } from '../TaskFeedDrawer';
import { useGetTasks } from '@/hooks/use-get-tasks';
import { setEventTracking } from '@/utils';

export const TaskHeaderIcon = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { data: tasks } = useGetTasks();

  const total = useMemo(() => tasks.pendingTasks?.length || 0, [tasks]);

  return (
    <>
      <MainContainer
        onClick={() => {
          setEventTracking('header_task_drawer_icon_click');
          setIsDrawerOpen(true);
        }}
      >
        {total > 0 ? <Number>{total}</Number> : null}
        <Container>
          <Icons
            name="IconCheckbox"
            size={16}
            fill="none"
            color="var(--color-neutral-50)"
          />
        </Container>
      </MainContainer>
      <TaskFeedDrawer
        visible={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      />
    </>
  );
};
