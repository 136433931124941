import {
  IconButton,
  Typography,
  Tag as DSTag,
} from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const FloatingContainer = styled.div<{ open: boolean }>`
  position: fixed;
  bottom: ${({ theme }) => theme.spacings.s};
  right: ${({ theme }) => theme.spacings.xs};

  transition: all 0.2s;
  opacity: ${({ open }) => (open ? '1' : '0')};
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
`;

export const HelpCenterContainer = styled.div<{ open: boolean }>`
  width: 386px;
  position: fixed;
  bottom: ${({ theme }) => theme.spacings.s};
  right: ${({ theme }) => theme.spacings.xs};
  background: ${({ theme }) => theme.colors.neutral[100]};
  border-radius: ${({ theme }) => theme.borders.radius.s};
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 26px;
  content-visibility: auto;

  transition: all 0.2s;
  opacity: ${({ open }) => (open ? '1' : '0')};
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};

  @media screen and (max-width: 400px) {
    width: 100%;
    right: 0;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.primary};
  padding: ${({ theme }) => theme.spacings.xs1}
    ${({ theme }) => theme.spacings.xs};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs2};
  padding: ${({ theme }) => theme.spacings.s}
    ${({ theme }) => theme.spacings.xs};
`;

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs2};
  border-radius: ${({ theme }) => theme.borders.radius.s};
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  padding: ${({ theme }) => theme.spacings.xs2};
`;

export const ExternalLink = styled(Typography)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs3};
  cursor: pointer;
`;

export const SectionList = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.borders.radius.s};
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
`;

export const FaqSectionLink = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs3};
  cursor: pointer;
  padding: ${({ theme }) => theme.spacings.xs2}
    ${({ theme }) => theme.spacings.xs2} ${({ theme }) => theme.spacings.xs2};

  & + & {
    border-top: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  }
`;

export const Tag = styled(DSTag)`
  margin-bottom: ${({ theme }) => theme.spacings.xs4};
  // fix tag oversize
  // TODO: move to DS
  padding: 2px 10px;
`;

export const StyledIconButton = styled(IconButton)`
  &&.iconButton-custom-theme-filled.MuiButtonBase-root {
    background: ${({ theme }) => theme.colors.primary};
  }
`;
