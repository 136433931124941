import { Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { BaseModal } from '../../BaseModal';
import { SpacedContainer, StyledPDFViewer } from '../contract-modal.styles';
import { useTranslation } from 'react-i18next';

interface ContractFlowProps {
  open: boolean;
  loading: boolean;
  onSign: () => void;
  pdfUrl: string;
}

export const ContractFlow = ({
  open,
  loading,
  pdfUrl,
  onSign,
}: ContractFlowProps) => {
  const [t] = useTranslation('translations', {
    keyPrefix: 'contractModal',
  });

  return (
    <BaseModal
      open={open}
      onNext={onSign}
      onNextText={
        <>
          {t('acceptButton')} <Icons name="IconCheck" />
        </>
      }
      loading={loading}
    >
      <SpacedContainer>
        <Typography variant="headline8">{t('title')}</Typography>
        <Typography variant="body4" variantColor="var(--color-neutral-40)">
          {`${t('textFirstLine')} ${t('textSecondLine')}`}
        </Typography>
      </SpacedContainer>
      <Typography
        variant="body4"
        weight={700}
        variantColor="var(--color-neutral-40)"
      >
        {t('indicationText')}
      </Typography>
      <StyledPDFViewer
        width="100%"
        height="400px"
        src={pdfUrl}
        options={{ showToolbar: true, fit: 'height', page: 1 }}
      />
    </BaseModal>
  );
};
