import styled from 'styled-components';
import { Typography, Tag as DSTag } from '@flash-tecnologia/hros-web-ui-v2';

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacings.xs3};
  gap: ${({ theme }) => theme.spacings.xs3};
  border-radius: ${({ theme }) => theme.borders.radius.s};
  background: ${({ theme }) => theme.colors.secondary[99]};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs4};
`;

export const EmailBlock = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs5};
  background: ${({ theme }) => theme.colors.neutral[100]};
  border-radius: ${({ theme }) => theme.borders.radius.s};
  padding: ${({ theme }) => theme.spacings.xs4};
`;

export const BalancedTypography = styled(Typography)`
  text-wrap: balance;
`;

export const Tag = styled(DSTag)`
  // fix tag oversize
  // TODO: move to DS
  padding: 2px 6px;
`;
