import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

const SPACING_X = 24;
const SPACING_Y = 0;
const GAP = 8;
const PADDING_RIGHT = 8;

export const StyledTaskList = styled.div`
  display: flex;
  width: calc(100% - ${SPACING_X * 2}px);
  height: calc(100% - ${SPACING_Y * 2}px);
  position: absolute;
  top: ${SPACING_Y}px;
  bottom: ${SPACING_Y}px;
  left: ${SPACING_X + PADDING_RIGHT / 2}px;
  right: calc(${SPACING_X}px - ${PADDING_RIGHT}px);
  flex-direction: column;
  gap: ${GAP}px;
  overflow-y: auto;
  padding-right: ${PADDING_RIGHT}px;
  box-sizing: border-box;
`;

export const StyledTaskListWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`;
