import RadioCardInput from './radio-card-input';
import RadioCardRoot from './radio-card-root/radio-card-root';
import RadioCardText from './radio-card-text/radio-card-text';

interface IRadioCard {
  Root: typeof RadioCardRoot;
  Radio: typeof RadioCardInput;
  Text: typeof RadioCardText;
}

export const RadioCard: IRadioCard = {
  Root: RadioCardRoot,
  Radio: RadioCardInput,
  Text: RadioCardText,
};
