import { ShapeIcon } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs3};
  padding: ${({ theme }) => theme.spacings.xs2}
    ${({ theme }) => theme.spacings.xs};
  margin-bottom: ${({ theme }) => theme.spacings.xs3};
  background: ${({ theme }) => theme.colors.feedback.error[90]};
`;

export const WarningShapeIcon = styled(ShapeIcon)`
  background: ${({ theme }) => theme.colors.feedback.error[70]};
`;
