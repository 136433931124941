import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const TextRadioCard = styled(Typography)<{
  checked: boolean;
  disabled?: boolean;
  $type?: string;
}>`
  font-weight: ${({ $type }) =>
    $type === 'title' ? '700' : 'normal'}!important;
  color: ${({ checked, disabled, $type, theme }) => {
    if (disabled) return theme.colors.neutral[70];
    if ($type === 'title') {
      if (checked) return theme.colors.secondary[50];
      return theme.colors.neutral[20];
    }
    return theme.colors.neutral[30];
  }};
`;
