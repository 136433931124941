import React from 'react';
import { Radio } from '@flash-tecnologia/hros-web-ui-v2';

interface IRadioCardInput {
  checked: boolean;
  disabled?: boolean;
  onChange: () => void;
}

const RadioCardInput: React.FC<IRadioCardInput> = ({
  checked,
  disabled,
  onChange,
}) => (
  <Radio
    checked={checked}
    disabled={disabled}
    id={'radio-card'}
    name={'radio-card'}
    onChange={onChange}
  />
);

export default RadioCardInput;
