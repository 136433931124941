import {
  IconButton,
  ShapeIcon,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

// TODO: replace for DS when fixed there
const StyledShapeIcon = styled(ShapeIcon)`
  padding: 8px;
  stroke-width: 1.2;
`;

const StepContainer = styled.div`
  max-width: 420px;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs2};
  align-items: center;
  text-align: center;
`;

const TourStepContainer = styled.div`
  max-width: 530px;
  margin-top: ${({ theme }) => theme.spacings.xs};
  margin-bottom: ${({ theme }) => theme.spacings.m};
`;

const TourStepImage = styled.img`
  border-radius: ${({ theme }) => theme.borders.radius.s};
  border: 1px solid ${({ theme }) => theme.colors.secondary[80]};
`;

const DescriptionText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: ${({ theme }) => theme.spacings.xs};
  right: ${({ theme }) => theme.spacings.xs};
`;

export {
  StepContainer,
  TourStepContainer,
  TourStepImage,
  DescriptionText,
  CloseButton,
  StyledShapeIcon,
};
