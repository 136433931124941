import styled from 'styled-components';

export const Banner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs};
  padding: ${({ theme }) => theme.spacings.xs};
  border-radius: ${({ theme }) => theme.borders.radius.s};
  border: 1px solid ${({ theme }) => theme.colors.error70};

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

export const ContentBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
`;

export const TextAndButtonBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const TextBox = styled.div`
  margin-bottom: ${({ theme }) => theme.spacings.xs2};
`;

export const Button = styled.button`
  height: 16px;
`;
