import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const EmptyStateWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  padding: 8px 24px;
`;

export const EmptyStateTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

export const EmptyStateTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[20]};
  text-align: center;
  word-break: auto-phrase;
`;

export const EmptyStateSubtitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[40]};
  text-align: center;
  word-break: auto-phrase;
`;
