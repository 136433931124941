enum EActions {
  SYSTEM_LOADING_START = 'system_loading_start',
  SYSTEM_LOADING_END = 'system_loading_end',
  GET_COMPANY = 'get_company',
}

interface IActions {
  type: EActions;
  payload?: any;
}

export { IActions, EActions };
