import { TaskFeedProps } from './types';
import { DrawerContainer, TaskFeedContentWrapper } from './styled';
import { DrawerHeader } from './components/Header';
import { TaskContent } from '../../components/QuickAccessSection/components/TaskWidget/components/TaskContent';
import { useTheme } from 'styled-components';

export const TaskFeedDrawer = ({ visible, onClose }: TaskFeedProps) => {
  const theme = useTheme();

  return (
    <DrawerContainer id="task-feed" visible={visible}>
      <DrawerHeader title="Tarefas" onClose={onClose} />
      <TaskFeedContentWrapper>
        <TaskContent bgColor={theme.colors.neutral[100]} />
      </TaskFeedContentWrapper>
    </DrawerContainer>
  );
};
