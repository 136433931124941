import { useEffect } from 'react';
import { segmentPageTrack } from '../shared/segment';

export const usePageTracking = (
  name: string,
  params?: Record<string, string>,
) => {
  useEffect(() => {
    segmentPageTrack({
      name,
      params,
      module: 'home',
      businessUnit: 'platform',
    });
  }, []);
};
