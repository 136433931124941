import { Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const StyledTaskGroupAccordion = styled.div<{ opened?: boolean }>`
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.neutral[100]};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: ${({ theme }) => theme.borders.radius.s};
  ${({ opened, theme }) =>
    opened &&
    `
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 1px solid ${theme.colors.neutral[90]};
  `}

  transition: border-radius 1s ease;
`;

export const StyledTaskGroupAccordionWrapper = styled.div<{ opened?: boolean }>`
  border-radius: ${({ theme }) => theme.borders.radius.s};
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
`;

export const StyledCollapseWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs3};
`;

export const StyledTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs5};
`;

export const StyledTitle = styled(Typography)`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.neutral[30]};
`;

export const StyledDescription = styled(Typography)`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.neutral[50]};
`;

export const StyledHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledChevronIcon = styled(Icons)<{ opened?: boolean }>`
  transform: ${({ opened }) => (opened ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 1s ease;
  transform-origin: center;
`;

export const StyledTaskList = styled.div`
  width: 100%;
`;

export const StyledTaskListActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 12px;
  background-color: ${({ theme }) => theme.colors.neutral[100]};
  border-bottom-left-radius: ${({ theme }) => theme.borders.radius.s};
  border-bottom-right-radius: ${({ theme }) => theme.borders.radius.s};
  cursor: pointer;
`;

export const StyledTaskItemLink = styled(Typography)`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.secondary[50]};
`;
