import { Widget } from '@/components/Widget';
import { StyledWidgetWrapper } from './styles';
import { TaskContent } from './components/TaskContent';
import { useTheme } from 'styled-components';

export const TaskWidget = () => {
  const theme = useTheme();

  return (
    <StyledWidgetWrapper>
      <Widget title="Tarefas" icon="IconCheckbox">
        <TaskContent bgColor={theme.colors.neutral[95]} />
      </Widget>
    </StyledWidgetWrapper>
  );
};
