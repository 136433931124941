import {
  segmentEventTracking,
  segmentPageTracking,
} from '@flash-tecnologia/hros-web-utility';

type ObjectLiteral = {
  [key: string]: string;
};

interface TrackEventProps {
  name: string;
  params?: ObjectLiteral;
  module: string;
  businessUnit: string;
}

export const segmentEventTrack: (props: TrackEventProps) => void =
  segmentEventTracking;

export const segmentPageTrack: (props: TrackEventProps) => void =
  segmentPageTracking;
