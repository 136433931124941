import {
  usePermissions,
  useSelectedCompany,
} from '@flash-tecnologia/hros-web-utility';
import NewMenuAvailable from './NewMenuAvailable/NewMenuAvailable';
import NewMenuFeedback from './NewMenuFeedback/NewMenuFeedback';
import { useFlag } from '@flash-tecnologia/feature-flags';
import { useMenuConfig } from '@/hooks/use-menu-config';
import { AlertBanner } from '@/pages/AdminHome/components/AlertBanner';

const AlertSection = () => {
  const permissions = usePermissions();
  const { selectedCompany } = useSelectedCompany();

  const isAdmin =
    permissions.isAdmin ||
    permissions.companies
      .find((company) => company.id === selectedCompany.id)
      ?.permissions?.includes('core_contract_products');

  const { getCurrentVersion } = useMenuConfig();

  const { enabled: alertBannerEnabled } = useFlag({
    flagName: 'FLASH_OS_HOME_ALERT_BANNER',
    variant: true,
  });

  const showMenuBanner = useFlag({
    flagName: 'FLASH_OS_SHOW_MENU_BANNER',
  });

  if (isAdmin && alertBannerEnabled) {
    return <AlertBanner />;
  }

  if (showMenuBanner) {
    const currentVersion = getCurrentVersion();
    if (currentVersion !== 2) {
      return <NewMenuAvailable />;
    }
    return <NewMenuFeedback />;
  }

  return null;
};
export default AlertSection;
