import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import {
  Button,
  Icons,
  LinkButton,
  Modal,
  TagCheckboxFilter,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import {
  Footer,
  MenuRow,
  QuickAccessList,
  TagCheckboxContainer,
} from './styles';
import { useEffect, useState } from 'react';
import { quickAccessList, quickAccessMap } from '../../quick-access.constants';
import { DraggableListItem } from '../ListItem';
import { QuickAccess } from '../QuickAccessItem';
import { reorderMap } from './utils';
import { loadQuickAccesses } from '../../quick-access.utils';
import { useTranslation } from 'react-i18next';

interface QuickAccessManagementModalProps {
  open: boolean;
  onConfirm: (quickAccess: QuickAccess[]) => void;
  onClose: () => void;
}

export const QuickAccessManagementModal = ({
  open,
  onConfirm,
  onClose,
}: QuickAccessManagementModalProps) => {
  const [t] = useTranslation('translations', {
    keyPrefix: 'quickAccess.modal',
  });
  const [selectedAccessesMap, setSelectedAccessesMap] = useState(
    new Map<string, QuickAccess>(),
  );

  const handleOptionCheck = (key: string) => {
    if (selectedAccessesMap.has(key)) {
      selectedAccessesMap.delete(key);
    } else {
      selectedAccessesMap.set(key, quickAccessMap.get(key)!);
    }

    setSelectedAccessesMap(new Map(selectedAccessesMap.entries()));
  };

  const quickAccessOptions = quickAccessList.map((item) => ({
    label: item.title,
    value: item.key,
    checked: selectedAccessesMap.has(item.key),
    setChecked: () => handleOptionCheck(item.key),
  }));

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) return;

    setSelectedAccessesMap(
      reorderMap(
        selectedAccessesMap,
        result.source.index,
        result.destination.index,
      ),
    );
  };

  const onRemoveItem = (key: string) => {
    selectedAccessesMap.delete(key);
    setSelectedAccessesMap(new Map(selectedAccessesMap.entries()));
  };

  const onFinish = () => onConfirm(Array.from(selectedAccessesMap.values()));

  useEffect(() => {
    if (open) {
      const quickAccesses = loadQuickAccesses();
      const quickAccessesMap = new Map(
        quickAccesses.map((item) => [item.key, item]),
      );
      setSelectedAccessesMap(quickAccessesMap);
    }
  }, [open]);

  return (
    <Modal.Root open={open} onClose={onClose}>
      <>
        <Modal.Header title={t('title')} description={t('description')} />
        <Modal.Content>
          <MenuRow>
            <Typography
              variant="headline8"
              variantColor="var(--color-secondary-50)"
            >
              {t('header')}
            </Typography>
            <TagCheckboxFilter
              variant="secondary"
              hasLeftIcon={false}
              hasRightIcon={false}
              options={quickAccessOptions}
              // skip onClick
              onClick={() => {}}
              filterLabel={
                <TagCheckboxContainer>
                  <Icons
                    name="IconPlus"
                    color="var(--color-neutral-40)"
                    size={16}
                  />
                  <Typography
                    variant="caption"
                    variantColor="var(--color-neutral-40)"
                    weight={700}
                  >
                    {t('addButton')}
                  </Typography>
                </TagCheckboxContainer>
              }
            />
          </MenuRow>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <QuickAccessList
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {Array.from(selectedAccessesMap.entries()).map(
                    ([key, quickAccess], index) => (
                      <DraggableListItem
                        key={key + index}
                        accessItem={quickAccess}
                        onRemove={() => onRemoveItem(key)}
                        index={index}
                      />
                    ),
                  )}
                  {provided.placeholder}
                </QuickAccessList>
              )}
            </Droppable>
          </DragDropContext>
        </Modal.Content>
        <Modal.Footer>
          <Footer>
            <LinkButton
              variant="primary"
              onClick={onClose}
              style={{ alignSelf: 'center' }}
            >
              Cancelar
            </LinkButton>
            <Button variant="primary" size="large" onClick={onFinish}>
              {t('confirmButton')}{' '}
              <Icons
                name="IconCheck"
                color="var(--color-neutral-100)"
                size={24}
              />
            </Button>
          </Footer>
        </Modal.Footer>
      </>
    </Modal.Root>
  );
};
