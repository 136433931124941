export type ModalStep = {
  name: string;
  text: string;
  header?: string;
  imagePath?: string;
};

export enum ONBOARDING_TOUR_STEPS {
  QUICK_ACCESS = 'quickAccess',
  SETUP = 'setup',
  COMPANIES = 'companies',
  FIRST_STEPS = 'firstSteps',
  PERMISSIONS = 'permissions',
}

export const modalSteps: Record<ONBOARDING_TOUR_STEPS, ModalStep> = {
  [ONBOARDING_TOUR_STEPS.QUICK_ACCESS]: {
    name: 'Acessos rápidos',
    text: 'Acesse as áreas de Benefícios, Expense e People pelo menu da plataforma, localizado na barra lateral.',
    imagePath:
      'https://images.flashapp.com.br/flash-os/know-plataform-tour-quick-access.gif',
  },
  [ONBOARDING_TOUR_STEPS.SETUP]: {
    name: 'Configurações',
    text: 'Personalize a plataforma do seu jeito: crie grupos, departamentos, cargos e muito mais acessando o ícone de engrenagem pelo menu.',
    imagePath:
      'https://images.flashapp.com.br/flash-os/know-plataform-tour-configurations.gif',
  },
  [ONBOARDING_TOUR_STEPS.COMPANIES]: {
    name: 'Empresas',
    text: ' Caso possua mais de uma empresa na sua organização, acesse-as pela barra superior da plataforma. Você poderá identificá-las por nome ou pelo número do CNPJ.',
    imagePath:
      'https://images.flashapp.com.br/flash-os/know-plataform-tour-companies.gif',
  },
  [ONBOARDING_TOUR_STEPS.FIRST_STEPS]: {
    name: 'Primeiros Passos',
    text: 'Faça as primeiras configurações básicas da sua empresa para usufruir de uma experiência mais Flash! Se preferir, poderá fechar os Primeiros passos da página inicial e acessá-lo pela barra superior da plataforma.',
    imagePath:
      'https://images.flashapp.com.br/flash-os/know-plataform-tour-first-steps.gif',
  },
  [ONBOARDING_TOUR_STEPS.PERMISSIONS]: {
    name: 'Permissões',
    header:
      'Agora, você deve definir acessos e permissões a outros administradores',
    text: 'Se houverem outras pessoas na empresa que precisam ter acesso ao pedido de benefícios, recomendamos que você conceda esses acessos agora. Você também pode fazer isso mais tarde na área de configurações.',
  },
};

export enum MODAL_STEPS {
  WELCOME,
  ONBOARDING_TOUR,
  FINISH,
}
