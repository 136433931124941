import { QuickAccess } from './components/QuickAccessItem';

export const quickAccessList: QuickAccess[] = [
  {
    key: 'pedido-beneficios',
    icon: 'IconShoppingCart',
    title: 'Pedir benefícios',
    url: '/benefits',
  },
  {
    key: 'cadastro-colaborador',
    icon: 'IconUserPlus',
    title: 'Cadastrar colaboradores',
    url: '/employees',
  },
  {
    key: 'pedido-cartoes',
    icon: 'IconCards',
    title: 'Pedir cartões',
    url: '/cards',
  },
];

export const quickAccessMap = new Map(
  quickAccessList.map((item) => [item.key, item]),
);
