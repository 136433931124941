import { useTranslation } from 'react-i18next';
import { PageContainer, PageHeader } from '@flash-tecnologia/hros-web-ui-v2';
import {
  MainPage,
  Container,
  AbsoluteIcon,
  StyledIcon,
  StyledIconContainer,
  TextsContainer,
  Title,
  SubTitle,
} from './employee-home.styles';
import { EmailConfirmModal } from '@/components';
import AlertSection from '@/components/AlertSection';
import { usePageTracking } from '@/hooks';
import { useSelectedCompany } from '@flash-tecnologia/hros-web-utility';

export const EmployeeHome = () => {
  const { selectedCompany } = useSelectedCompany();
  const [t] = useTranslation('translations', {
    keyPrefix: 'homePage',
  });

  usePageTracking('home_screen', {
    view: 'employee',
    companyId: selectedCompany?.id,
    companyName: selectedCompany?.name,
  });

  return (
    <>
      <AlertSection />
      <MainPage>
        <PageHeader title={t('home')}></PageHeader>
        <PageContainer style={{ justifyContent: 'center' }}>
          <EmailConfirmModal />
          <Container>
            <AbsoluteIcon>
              <StyledIconContainer>
                <StyledIcon size={56} name="IconRocket" fill="transparent" />
              </StyledIconContainer>
            </AbsoluteIcon>

            <TextsContainer>
              <Title variant="headline7">{t('contentTitle')}</Title>
              <SubTitle variant="body3">{t('subTitle')}</SubTitle>
            </TextsContainer>
          </Container>
        </PageContainer>
      </MainPage>
    </>
  );
};
