import { Modal } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Content = styled(Modal.Content)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs1};
  padding: ${({ theme }) =>
    `${theme.spacings.xs4} ${theme.spacings.m} ${theme.spacings.xs}`};
`;

export const Footer = styled.div`
  display: flex;
  padding: ${({ theme }) => `${theme.spacings.xs} ${theme.spacings.m} 0`};
  border-top: 1px solid ${({ theme }) => theme.colors.neutral[90]};
`;
