import { dayjs } from '@flash-tecnologia/hros-web-ui-v2';

export const formatExpiration = (date: Date): string => {
  const now = new Date();
  const diff = now.getTime() - date.getTime();

  const oneDay = 1000 * 60 * 60 * 24;
  const oneWeek = oneDay * 7;
  const oneMonth = oneDay * 30;

  if (diff > oneMonth) {
    return 'Expirou há muito tempo';
  }

  if (diff > oneWeek) {
    return 'Expirou há uma semana';
  }
  if (diff > oneDay) {
    return 'Expirou ontem';
  }

  return `Expira em ${dayjs(date).format('DD/MM/YYYY')}`;
};
