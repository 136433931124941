import { Icons, iconsOptions } from '@flash-tecnologia/hros-web-ui-v2';
import {
  StyledSection,
  StyledSectionHeader,
  StyledSectionHeaderTitle,
} from './styles';
import { useTheme } from 'styled-components';
import { ReactNode } from 'react';

type WidgetProps = {
  children: ReactNode;
  title: string;
  icon: typeof iconsOptions[number];
};

export const Widget = ({ title, icon, children }: WidgetProps) => {
  const theme = useTheme();

  return (
    <StyledSection>
      <StyledSectionHeader>
        <Icons name={icon} size={16} color={theme.colors.neutral[60]} />
        <StyledSectionHeaderTitle variant="headline10">
          {title}
        </StyledSectionHeaderTitle>
      </StyledSectionHeader>
      {children}
    </StyledSection>
  );
};
