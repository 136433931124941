import { ReactNode } from 'react';
import { StyledTaskList, StyledTaskListWrapper } from './styles';

type TaskListProps = {
  children: ReactNode;
};

export const TaskList = ({ children }: TaskListProps) => {
  return (
    <>
      <StyledTaskListWrapper>
        <StyledTaskList>{children}</StyledTaskList>
      </StyledTaskListWrapper>
    </>
  );
};
