import { useContext } from 'react';
import { Context } from '../../context/configuration';

export default (props) => {
  const { children, url } = props;

  const { loading } = useContext(Context);

  const isLoading =
    url.constructor == Array
      ? loading.urls.some((slu) => url.find((u) => slu == u))
      : loading.urls.includes(url);

  if (isLoading) {
    return children({ loading: true });
  }

  return children({ loading: false });
};
