import { getFromLS, setInLS } from '@flash-tecnologia/hros-web-utility';
import { QuickAccess } from './components/QuickAccessItem';
import { quickAccessList, quickAccessMap } from './quick-access.constants';

const QUICK_ACCESS_LS_KEY = 'home-quick-access';

export const saveQuickAccesses = (quickAccessKeys: string[]) => {
  setInLS({
    key: QUICK_ACCESS_LS_KEY,
    value: JSON.stringify(quickAccessKeys),
  });
};

export const loadQuickAccesses = (): QuickAccess[] => {
  const quickAccessJSON = getFromLS(QUICK_ACCESS_LS_KEY);

  // We show a predefined list if the user has never saved any other.
  if (quickAccessJSON === undefined) {
    return quickAccessList;
  }

  const quickAccessKeys = JSON.parse(quickAccessJSON);
  const quickAccess: QuickAccess[] = [];

  for (const key of quickAccessKeys) {
    const accessExist = quickAccessMap.get(key);
    if (accessExist) {
      quickAccess.push(accessExist);
    }
  }

  return quickAccess;
};
