import styled from 'styled-components';

export const StyledTitleTabWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs4};
`;

export const StyledTitleTabBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.borders.radius.circular};
  background-color: ${({ theme }) => theme.colors.primary};
  width: 18px;
  height: 18px;
  color: white;
`;
