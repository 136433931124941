import { ShapeIcon, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { Banner, ContentBox, TextAndButtonBox, TextBox } from './styles';

const AlertBanner = () => (
  <Banner>
    <ShapeIcon
      name="IconAlertTriangle"
      size={56}
      variant="default"
      color="var(--color-feedback-error-70)"
      style={{
        flexShrink: 0,
        backgroundColor: 'var(--color-feedback-error-90)',
      }}
    />
    <ContentBox>
      <TextAndButtonBox>
        <TextBox>
          <Typography
            variant="body3"
            weight={700}
            style={{ color: 'var(--color-feedback-error-40)' }}
          >
            Importante
          </Typography>
          <Typography
            variant="body4"
            style={{ color: 'var(--color-primary-50)' }}
          >
            Lembre-se que agora os acessos são únicos, pessoais e
            intransferíveis e, por isso os outros administradores, só
            conseguirão acessar a nova plataforma se forem atribuídos novamente
            a um perfil com permissão de administrador.
          </Typography>
        </TextBox>
      </TextAndButtonBox>
    </ContentBox>
  </Banner>
);

export default AlertBanner;
