import styled from 'styled-components';

export const TaskFeedContentWrapper = styled.div`
  padding: 0 25px;
  height: 100%;
`;

export const DrawerContainer = styled.div<{ visible?: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  width: 583px;
  height: 100vh;
  box-shadow: 0px 12px 24px rgba(21, 25, 28, 0.1);
  background-color: #ffffff;
  padding-bottom: 40px;
  margin-right: ${({ visible }) => (visible ? 0 : '-583px')};
  transition: 0.4s;
  word-break: break-all;
  overflow-y: auto;
  z-index: 4;
  display: flex;
  flex-direction: column;
`;

export const DrawerContainerLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
