import { useTheme } from 'styled-components';
import {
  StyledTaskItem,
  StyledTaskItemDescription,
  StyledTaskItemLink,
  StyledTaskItemLinkWrapper,
  StyledTaskItemTitle,
  StyledTaskItemTitleWrapper,
  StyledTaskTagWrapper,
} from './styles';
import { Icons, Tooltip } from '@flash-tecnologia/hros-web-ui-v2';
import { ReactNode } from 'react';

export type ExpiredTaskItemProps = {
  title: string;
  description: string;
  path: string;
  btnText: string;
  tags?: ReactNode;
  index?: number;
  style?: {
    radius?: boolean;
    border?: boolean;
    borderBottom?: boolean;
    radiusBottom?: boolean;
  };
};

export const Task = ({
  tags,
  title,
  description,
  path,
  index = 0,
  style,
  btnText,
}: ExpiredTaskItemProps) => {
  const theme = useTheme();

  return (
    <StyledTaskItem
      radiusBottom={style?.radiusBottom}
      borderBottom={style?.borderBottom}
      radius={style?.radius}
      border={style?.border}
      index={index}
    >
      <StyledTaskItemTitleWrapper>
        {tags && <StyledTaskTagWrapper>{tags}</StyledTaskTagWrapper>}

        <StyledTaskItemTitle variant="body4" weight={600}>
          <Tooltip title={title} arrow={false}>
            <span>{title}</span>
          </Tooltip>
        </StyledTaskItemTitle>

        <StyledTaskItemDescription variant="caption" weight={400}>
          <Tooltip title={description} arrow={false}>
            <span>{description}</span>
          </Tooltip>
        </StyledTaskItemDescription>
      </StyledTaskItemTitleWrapper>
      <StyledTaskItemLinkWrapper href={path} target="__blank">
        <StyledTaskItemLink variant="body4" weight={700}>
          {btnText}
        </StyledTaskItemLink>
        <Icons
          name="IconChevronRight"
          size={18}
          color={theme.colors.secondary[50]}
        />
      </StyledTaskItemLinkWrapper>
    </StyledTaskItem>
  );
};
