import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  gap: ${({ theme }) => theme.spacings.xs4};
`;

export const LoadingContainer = styled.div`
  max-width: 660px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs};
  padding: 120px;
  border-radius: ${({ theme }) => theme.borders.radius.s};
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  background: ${({ theme }) => theme.colors.neutral[100]};
`;
