import { VariantsType } from '@flash-tecnologia/hros-web-ui-v2';
import { TextRadioCard } from './styles';
import React from 'react';

export enum RadioCardTextTypeEnum {
  TITLE = 'title',
  DESCRIPTION = 'description',
}
interface IRadioCardText {
  checked: boolean;
  disabled?: boolean;
  text: string;
  textVariant: VariantsType;
  type?: RadioCardTextTypeEnum;
}

const RadioCardText: React.FC<IRadioCardText> = ({
  checked,
  disabled,
  text,
  textVariant,
  type,
}) => (
  <TextRadioCard
    checked={checked}
    disabled={disabled}
    $type={type}
    variant={textVariant}
  >
    {text}
  </TextRadioCard>
);

export default RadioCardText;
