import styled from 'styled-components';

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs4};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  margin-top: 40px;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 375px) {
    flex-direction: column-reverse;
    gap: 15px;
  }
`;
