import { useEffect, useState } from 'react';
import { useSelectedCompany } from '@flash-tecnologia/hros-web-utility';
import { useFlag } from '@flash-tecnologia/feature-flags';
import {
  useGetAmendmentSignedUrl,
  useGetBenefitsContract,
  useGetContractSignedUrl,
  useSignContract,
} from '@hooks';
import { dispatchToast } from '@/utils';
import { ContractFlow } from './flows/contract-flow';
import { AmendmentFlow } from './flows/amendment-flow';
import { useSignAmendment } from '@/hooks/use-sign-amendment';

type Contract = {
  id: string;
  pdfUrl: string;
};

type Amendment = {
  id: string;
  pdfUrl: string;
  contractId: string;
};

export const ContractModal = () => {
  const isAmendmentFlowEnabled = useFlag({
    flagName: 'FLASH_OS_AMENDMENT_FLOW',
  });
  const { getContractByCompanyAndPlan } = useGetBenefitsContract();
  const { signContract } = useSignContract();
  const { signAmendment } = useSignAmendment();
  const { getContractSignedUrl } = useGetContractSignedUrl();
  const { getAmendmentSignedUrl } = useGetAmendmentSignedUrl();
  const { selectedCompany } = useSelectedCompany();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [contract, setContract] = useState<Contract | undefined>();
  const [amendment, setAmendment] = useState<Amendment | undefined>();

  const onClose = () => {
    setOpen(false);
  };

  const onSignContract = async () => {
    setLoading(true);

    try {
      if (contract) {
        await signContract({ contractId: contract.id });
        onClose();
      }

      if (amendment) {
        await signAmendment({
          amendmentId: amendment.id,
          contractId: amendment.contractId,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    async function getContract() {
      const benefitsContract = await getContractByCompanyAndPlan({
        companyId: selectedCompany.id,
        plan: 'benefit',
      });
      if (!benefitsContract) return;

      if (benefitsContract.status === 'WAITING_SIGNATURE') {
        const contractSignedUrl = await getContractSignedUrl({
          contractId: benefitsContract.id,
        });

        setContract({
          id: benefitsContract.id,
          pdfUrl: contractSignedUrl.url,
        });
        setOpen(true);
        return;
      }

      if (isAmendmentFlowEnabled) return;

      const unsignedAmendment = benefitsContract.amendments.find(
        (amendment) => !amendment.isSigned,
      );

      if (!unsignedAmendment) return;

      const amendmentSignedUrl = await getAmendmentSignedUrl({
        contractId: benefitsContract.id,
        amendmentId: unsignedAmendment.id,
      });

      setAmendment({
        id: unsignedAmendment.id,
        pdfUrl: amendmentSignedUrl.url,
        contractId: benefitsContract.id,
      });
      setOpen(true);
    }

    // Temporary disable this flow while moving out to header
    // getContract();
  }, [selectedCompany?.id]);

  return (
    <>
      {contract && (
        <ContractFlow
          open={open}
          loading={loading}
          onSign={onSignContract}
          pdfUrl={contract.pdfUrl}
        />
      )}
      {amendment && (
        <AmendmentFlow
          open={open}
          loading={loading}
          pdfUrl={amendment.pdfUrl}
          onSign={onSignContract}
          onClose={onClose}
        />
      )}
    </>
  );
};
