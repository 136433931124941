import { QueryClient } from '@tanstack/react-query';
import { createTRPCReact, httpLink } from '@trpc/react-query';
import type { AppRouter } from 'bff';
import { getFromLS } from '@flash-tecnologia/hros-web-utility';

export const trpc = createTRPCReact<AppRouter>();

export const queryClient = new QueryClient();
export const trpcClient = trpc.createClient({
  links: [
    httpLink({
      url: `${process.env.BFF_URL}`,
      headers: async () => {
        const token = getFromLS('hrosAccessToken')?.accessToken;
        if (token)
          return {
            Authorization: `Bearer ${token}`,
            'x-flash-auth': `Bearer ${token}`,
          };
        return {};
      },
    }),
  ],
});
