import { QuickAccess } from '../QuickAccessItem';

export const reorderMap = (
  map: Map<string, QuickAccess>,
  startIndex: number,
  endIndex: number,
) => {
  const result = Array.from(map.entries());
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return new Map(result);
};
