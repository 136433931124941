import { useMenuConfig } from '@/hooks/use-menu-config';
import { Container, IconContainer, InfoContainer, TextButton } from './styled';
import { Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { setEventTracking } from '@/utils';

const NewMenuFeedback = () => {
  const { setCurrentVersion } = useMenuConfig();
  const goBackToOldVersion = () => {
    setCurrentVersion(1);
    setEventTracking('menu_v2_return_to_v1_clicked');
    window.location.reload();
  };

  // const setHasSeenMenuV2FeedbackAlert = () => {
  //   setHasSeenV2Feedback(true);
  //   setVisible(false);
  // };
  // const [visible, setVisible] = useState(true);

  // if (!visible || loading) return <></>;
  return (
    <Container>
      <InfoContainer>
        <IconContainer>
          <Icons
            name="IconSparkles"
            fill="none"
            width={16}
            height={16}
            color="var(--color-secondary-50)"
          />
        </IconContainer>
        <Typography
          variant="body3"
          variantColor="var(--color-secondary-50)"
          weight={600}
        >
          Você está visualizando a nova versão do menu. Continue navegando nessa
          versão e{' '}
          <TextButton
            href="https://surveys.hotjar.com/eeb5dc0f-0721-42ff-9692-b4054790c5aa"
            target="_blank"
          >
            <b>deixe seu feedback</b>
          </TextButton>{' '}
          ou{' '}
          <TextButton onClick={goBackToOldVersion}>
            <b>volte para a versão anterior</b>
          </TextButton>
          .
        </Typography>
      </InfoContainer>
      {/* <Icons
        name="IconX"
        fill="none"
        width={16}
        height={16}
        color="var(--color-secondary-50)"
        strokeWidth="3px"
        onClick={setHasSeenMenuV2FeedbackAlert}
        style={{ cursor: 'pointer' }}
      /> */}
    </Container>
  );
};

export default NewMenuFeedback;
