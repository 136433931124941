import { Tabs } from '@/components/Tabs';
import { useMemo } from 'react';
import { PendingTaskGroup, TaskType } from '../../types';
import { useGetTasks } from '@/hooks/use-get-tasks';
import { TaskList } from '../TaskList';
import { TaskGroup } from '../TaskGroup';
import { ICONS, LABELS } from '../../constants';
import { Tag } from '../Tag';
import { dayjs, Dot, Icons } from '@flash-tecnologia/hros-web-ui-v2';
import { TabTitle } from '@/components/Tabs/components/TabTitle';
import { Task } from '../Task';
import {
  StyledListLabel,
  StyledTabContent,
  StyledTasksListLabel,
  StyledTasksListLabelWrapper,
  StyledTypeIcon,
} from '../../styles';
import { TabContentSkeleton } from '../TabContentSkeleton';
import { EmptyState } from '../EmptyState';
import { PendingIcon } from '../EmptyState/Icons/PendingIcon';
import { CompletedIcon } from '../EmptyState/Icons/CompletedIcon';

type TaskContentProps = {
  bgColor: string;
};

export const TaskContent = ({ bgColor }: TaskContentProps) => {
  const { isLoading: tasksIsLoading, data: tasks } = useGetTasks();

  const formatedPendingTasks = useMemo(() => {
    let formattedTasks: Partial<Record<TaskType, PendingTaskGroup>> = {};
    const todayDate = new Date().setHours(0, 0, 0, 0);

    tasks.pendingTasks?.forEach((task) => {
      let taskTypeGroup = formattedTasks[task.type];
      if (!taskTypeGroup) {
        taskTypeGroup = {
          type: task.type,
          expiresToday: 0,
          tasks: [],
        };
      }

      const isTaskDueToday = task.dueDate
        ? new Date(task.dueDate).setHours(0, 0, 0, 0) === todayDate
        : false;

      if (isTaskDueToday) {
        taskTypeGroup.expiresToday += 1;
      }

      taskTypeGroup.tasks.push(task);

      formattedTasks[task.type] = taskTypeGroup;
    });

    return formattedTasks;
  }, [tasks.pendingTasks]);

  const groupPendingTasks = useMemo(
    () =>
      Object.entries(formatedPendingTasks).map(([_, taskGroup]) => {
        return {
          type: taskGroup.type,
          pendingTasksCount: taskGroup.tasks.length,
          expiresToday: taskGroup.expiresToday,
          tasks: taskGroup.tasks,
        };
      }),
    [tasks.pendingTasks],
  );

  return (
    <Tabs
      tabs={[
        {
          title: (
            <TabTitle
              text="Pendentes"
              value={tasks.pendingTasks?.length ?? 0}
            />
          ),
          content: !tasksIsLoading ? (
            <StyledTabContent bgColor={bgColor}>
              {groupPendingTasks.length > 0 ? (
                <TaskList>
                  {groupPendingTasks.map((group) => (
                    <TaskGroup
                      title={LABELS[group.type]}
                      description={`${group.pendingTasksCount} tarefas pendentes`}
                      icon={ICONS[group.type]}
                      rightIcon={
                        group.expiresToday > 0 && (
                          <Tag
                            type="WARNING"
                            text={`${group.expiresToday} vencem hoje`}
                            leftIcon={<Icons name="IconClockHour3" size={14} />}
                          />
                        )
                      }
                      tasks={group.tasks}
                      key={group.type}
                    />
                  ))}
                </TaskList>
              ) : (
                <EmptyState
                  title="Você está em dia com suas tarefas!"
                  subtitle="Aproveite para pegar um cafézinho."
                  icon={<PendingIcon />}
                />
              )}
            </StyledTabContent>
          ) : (
            <TabContentSkeleton length={4} height={74} />
          ),
        },
        {
          title: 'Concluídas',
          content: (
            <StyledTabContent bgColor={bgColor}>
              {tasks.resolvedTasks && tasks.resolvedTasks?.length > 0 ? (
                <>
                  <StyledListLabel>
                    <StyledTasksListLabelWrapper bgColor={bgColor}>
                      <StyledTasksListLabel variant="body4" bgColor={bgColor}>
                        Consulte as tarefas concluídas nos últimos 30 dias.
                      </StyledTasksListLabel>
                    </StyledTasksListLabelWrapper>
                  </StyledListLabel>
                  <TaskList>
                    {tasks.resolvedTasks?.map((task, index) => (
                      <Task
                        tags={
                          <>
                            <Tag
                              text={LABELS[task.type]}
                              type="DEFAULT"
                              leftIcon={
                                <StyledTypeIcon
                                  name={ICONS[task.type]}
                                  size={14}
                                />
                              }
                            />
                            <Tag
                              text={`Concluído em ${dayjs(
                                task.resolvedAt,
                              ).format('DD/MM/YYYY')}`}
                              type="SUCCESS"
                              leftIcon={<Dot variant="green" />}
                            />
                          </>
                        }
                        key={task.id}
                        title={task.title}
                        description={task.description}
                        path={task.redirectPath}
                        index={index}
                        style={{
                          border: true,
                          radius: true,
                        }}
                        btnText="Ver"
                      />
                    ))}
                  </TaskList>
                </>
              ) : (
                <EmptyState
                  title="Nenhuma tarefa por aqui"
                  subtitle="As tarefas concluídas são exibidas aqui por até 30 dias. Depois desse período, você pode encontrá-las nas páginas de cada ferramenta."
                  icon={<CompletedIcon />}
                />
              )}
            </StyledTabContent>
          ),
        },
        {
          title: 'Expiradas',
          content: (
            <StyledTabContent bgColor={bgColor}>
              {tasks.expiredTasks && tasks.expiredTasks.length > 0 ? (
                <>
                  <StyledListLabel>
                    <StyledTasksListLabelWrapper bgColor={bgColor}>
                      <StyledTasksListLabel variant="body4" bgColor={bgColor}>
                        Consulte as tarefas expiradas nos últimos 30 dias.
                      </StyledTasksListLabel>
                    </StyledTasksListLabelWrapper>
                  </StyledListLabel>
                  <TaskList>
                    {tasks.expiredTasks?.map((task, index) => (
                      <Task
                        tags={
                          <>
                            <Tag
                              text={LABELS[task.type]}
                              type="DEFAULT"
                              leftIcon={
                                <StyledTypeIcon
                                  name={ICONS[task.type]}
                                  size={14}
                                />
                              }
                            />
                            <Tag
                              text={`Expirado em ${dayjs(task.dueDate).format(
                                'DD/MM/YYYY',
                              )}`}
                              type="WARNING"
                              leftIcon={<Dot variant="yellow" />}
                            />
                          </>
                        }
                        key={task.id}
                        title={task.title}
                        description={task.description}
                        path={task.redirectPath}
                        index={index}
                        style={{
                          border: true,
                          radius: true,
                        }}
                        btnText="Ver"
                      />
                    ))}
                  </TaskList>
                </>
              ) : (
                <EmptyState
                  title="Nenhuma tarefa por aqui"
                  subtitle="As tarefas expiradas são exibidas aqui por até 30 dias. Depois desse período, você pode encontrá-las nas páginas de cada ferramenta."
                  icon={<CompletedIcon />}
                />
              )}
            </StyledTabContent>
          ),
        },
      ]}
    />
  );
};
