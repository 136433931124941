import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled, { useTheme } from 'styled-components';
import { TYPES } from '.';

const GetBackgroundColor = (type: TYPES) => {
  const theme = useTheme();

  const colors: Record<TYPES, string> = {
    WARNING: theme.colors.feedback.error[90],
    SUCCESS: theme.colors.feedback.success[90],
    DEFAULT: theme.colors.secondary[95],
    NEUTRAL: theme.colors.neutral[90],
  };

  return colors[type];
};

const GetTextColor = (type: TYPES) => {
  const theme = useTheme();

  const colors: Record<TYPES, string> = {
    WARNING: theme.colors.feedback.error[10],
    SUCCESS: theme.colors.feedback.success[10],
    DEFAULT: theme.colors.secondary[50],
    NEUTRAL: theme.colors.neutral[10],
  };

  return colors[type];
};

export const StyledTag = styled.div<{ type: TYPES }>`
  padding: 4px 12px;
  background-color: ${({ type }) => GetBackgroundColor(type)};
  border-radius: ${({ theme }) => theme.borders.radius.pill};
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs5};
`;

export const StyledTagLabel = styled(Typography)<{ type: TYPES }>`
  font-size: 12px;
  color: ${({ type }) => GetTextColor(type)};
`;
