import { trpc } from '@api/client';

export const useGetBenefitsContract = () => {
  const { mutateAsync: getContractsV2, isLoading } =
    trpc.getContractsByCompanyId.useMutation();

  const getContractByCompanyAndPlan = async (input: {
    companyId: string;
    plan: string;
  }) => {
    const { contracts } = await getContractsV2({
      companyId: input.companyId,
    });

    const contract = contracts.find((contract) => {
      return contract.planIds.some((planId) => planId.includes(input.plan));
    });

    return contract;
  };

  return {
    getContractByCompanyAndPlan,
    isLoading,
  };
};
