import {
  Icons,
  LinkButton,
  PageContainer,
  ShapeIcon,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import {
  Banner,
  Button,
  ContentBox,
  TextAndButtonBox,
  TextBox,
} from './styles';
import { useNavigate } from 'react-router-dom';
import { setEventTracking } from '@utils';
import { useOnboarding } from '@context/onboarding/hook';

export const PermissionsBanner = () => {
  const { showAdminBanner, closeAdminBanner } = useOnboarding();
  const navigate = useNavigate();

  const handleCloseClick = () => {
    setEventTracking('home_onboardingrootwarning_clicked');
    closeAdminBanner();
  };

  if (!showAdminBanner) {
    return null;
  } else {
    setEventTracking('home_onboardingrootwarning_viewed');
  }

  return (
    <PageContainer>
      <Banner>
        <ShapeIcon
          name="IconAlertTriangle"
          size={56}
          variant="default"
          color="var(--color-feedback-error-70)"
          style={{
            flexShrink: 0,
            backgroundColor: 'var(--color-feedback-error-90)',
          }}
        />
        <ContentBox>
          <TextAndButtonBox>
            <TextBox>
              <Typography
                variant="body3"
                style={{ color: 'var(--color-primary-50)' }}
              >
                Você chegou! Boas-vindas a nova experiência da Flash
              </Typography>
              <Typography
                variant="body4"
                style={{ color: 'var(--color-primary-50)' }}
              >
                É necessário <b>atribuir ou criar perfis de permissão</b> aos
                outros <b>administradores da plataforma de benefícios</b> na
                área de acessos e permissões.
              </Typography>
            </TextBox>
            <LinkButton
              variant="error"
              style={{ color: 'var(--color-feedback-error-40' }}
              onClick={() => navigate('/settings/permissions')}
            >
              Ir para acessos e permissões
            </LinkButton>
          </TextAndButtonBox>

          <Button type="button" onClick={handleCloseClick}>
            <Icons
              name="IconX"
              fill="none"
              size={16}
              stroke={'1.2px'}
              color={'var(--color-feedback-error-40)'}
            />
          </Button>
        </ContentBox>
      </Banner>
    </PageContainer>
  );
};
