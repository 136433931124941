import { useEffect, useState } from 'react';
import {
  AuthenticatedUser,
  getAuthenticatedUser,
} from '@flash-tecnologia/hros-web-utility';
import { Modal } from '@flash-tecnologia/hros-web-ui-v2';

import { ConfirmEmailStep } from './components/ConfirmEmailStep';
import { FluxEndStep } from './components/FluxEndStep';

export const EmailConfirmModal = () => {
  const [userAuth, setUserAuth] = useState<AuthenticatedUser>();
  const [modalStep, setModalStep] = useState<'ConfirmEmailModal' | 'FluxEnd'>(
    'ConfirmEmailModal',
  );
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    async function getAuthUser() {
      const authenticatedUser = await getAuthenticatedUser();

      if (authenticatedUser?.email && !authenticatedUser.emailVerified) {
        setOpenModal(true);
      }

      setUserAuth(authenticatedUser);
    }

    getAuthUser();
  }, []);

  const renderContent = () => {
    switch (modalStep) {
      case 'ConfirmEmailModal':
        return (
          <>
            <Modal.Header
              title="Confirme seu endereço de e-mail"
              description="Mantenha a sua conta segura confirmando o e-mail informado."
            />
            <Modal.Content>
              <ConfirmEmailStep
                authenticatedUser={userAuth as AuthenticatedUser}
                onSubmit={() => setModalStep('FluxEnd')}
                onClose={() => setOpenModal(false)}
              />
            </Modal.Content>
          </>
        );
      case 'FluxEnd':
        return (
          <Modal.Content>
            <FluxEndStep
              username={userAuth?.name}
              onClose={() => setOpenModal(false)}
            />
          </Modal.Content>
        );
    }
  };

  return (
    <Modal.Root
      open={openModal}
      onClose={() => setOpenModal(false)}
      size={modalStep === 'FluxEnd' ? 'xs' : 'sm'}
    >
      {renderContent()}
    </Modal.Root>
  );
};
