import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { useFlag } from '@flash-tecnologia/feature-flags';
import { Container, WarningShapeIcon } from './styles';
import { parseFeatureFlagPayload } from '@/utils';

interface AlertBannerFlag {
  cause: string;
  description: string;
}

export const AlertBanner = () => {
  const { enabled, payload } = useFlag({
    flagName: 'FLASH_OS_HOME_ALERT_BANNER',
    variant: true,
  });

  if (!enabled) return null;

  const variant = parseFeatureFlagPayload<AlertBannerFlag>(
    payload?.value || '',
  );

  if (!variant) return null;

  return (
    <Container>
      <WarningShapeIcon
        name="IconAlertTriangle"
        variant="error"
        size={24}
        color="var(--color-feedback-error-40)"
        style={{ flexShrink: 0 }}
      />
      <Typography variant="body3" variantColor="var(--color-feedback-error-10)">
        <strong>{variant.cause}</strong> - {variant.description}
      </Typography>
    </Container>
  );
};
