import {
  Button,
  Icons,
  IconsProps,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import { parseFeatureFlagPayload, setEventTracking } from '@utils';
import {
  ContentContainer,
  TextsContainer,
  ImageContainer,
  Item,
  ItemsContainer,
  Section,
  Logo,
  BannerSize,
} from './styles';
import { useFlag } from '@flash-tecnologia/feature-flags';
import useResizeObserver from 'use-resize-observer';

type PromotionalBanner = {
  title?: string;
  headerLogoUrl?: string;
  headline: string;
  description: string;
  bannerImageUrl: string;
  buttonText: string;
  buttonLinkUrl: string;
  shouldOpenInNewTab: boolean;
  itemsList: Array<{
    iconName: IconsProps['name'];
    text: string;
  }>;
};

export const FLASH_OS_HOME_PROMOTIONAL_BANNER =
  'FLASH_OS_HOME_PROMOTIONAL_BANNER';

const getBannerSize = (width: number): BannerSize => {
  if (width <= 600) return 'small';
  if (width <= 860) return 'medium';
  if (width <= 1100) return 'large';
  return 'full';
};

export const PromotionalBanner = () => {
  const { ref: containerRef, width: containerWidth = 1 } =
    useResizeObserver<HTMLDivElement>();
  const bannerVariant = useFlag({
    flagName: FLASH_OS_HOME_PROMOTIONAL_BANNER,
    variant: true,
  });

  const bannerProps = parseFeatureFlagPayload<PromotionalBanner>(
    bannerVariant.payload?.value || '',
  );

  if (!bannerProps) {
    return null;
  }

  const onPromotionalBannerClick = () => {
    setEventTracking('home_promotional_banner_clicked', {
      variant_name: bannerVariant.name,
    });

    const target = bannerProps.shouldOpenInNewTab ? '_blank' : '_self';
    window.open(bannerProps.buttonLinkUrl, target);
  };

  const bannerSize = getBannerSize(containerWidth);
  const useLogoAsTitle = !!bannerProps.headerLogoUrl;

  return (
    <Section ref={containerRef} $bannerSize={bannerSize}>
      <ContentContainer $bannerSize={bannerSize}>
        <TextsContainer>
          {useLogoAsTitle && (
            <Logo
              src={bannerProps.headerLogoUrl}
              alt="Logo do banner promocional"
            />
          )}
          {!useLogoAsTitle && (
            <Typography
              variant="headline9"
              variantColor="var(--color-secondary-40)"
            >
              {bannerProps.title}
            </Typography>
          )}
          <Typography
            variant="headline7"
            variantColor="var(--color-secondary-50)"
          >
            {bannerProps.headline}
          </Typography>
          <Typography variant="body3" variantColor="var(--color-secondary-40)">
            {bannerProps.description}
          </Typography>
        </TextsContainer>
        <Button
          size="small"
          variant="primary"
          onClick={onPromotionalBannerClick}
        >
          {bannerProps.buttonText} <Icons name="IconArrowRight" />
        </Button>
      </ContentContainer>
      {bannerSize !== 'small' && (
        <ItemsContainer $bannerSize={bannerSize}>
          {bannerProps.itemsList.map((item, i) => (
            <Item key={`promotional-banner-item-${i}`}>
              <Icons
                name={item.iconName}
                color="var(--color-secondary-40)"
                size={32}
                fill="none"
                style={{ flexShrink: 0 }}
              />
              <Typography
                variant="body3"
                variantColor="var(--color-secondary-40)"
              >
                {item.text}
              </Typography>
            </Item>
          ))}
        </ItemsContainer>
      )}
      {(bannerSize === 'large' || bannerSize === 'full') && (
        <ImageContainer
          $bannerSize={bannerSize}
          $imageUrl={bannerProps.bannerImageUrl}
        />
      )}
    </Section>
  );
};
