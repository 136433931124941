import styled from 'styled-components';

const MainContainer = styled.div`
  position: relative;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    margin-right: 0px;
  }
`;

const Number = styled.div`
  color: #ffffff;
  background-color: #fe2b8f;
  height: 12px;
  line-height: 12px;
  font-size: 8px;
  padding: 4px;
  border-radius: 24px;
  font-weight: 700;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -8px;
  right: -8px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  svg {
    &:hover {
      opacity: 0.5;
    }
  }
`;

export { MainContainer, Number, Container };
