import { ButtonContainer, TextContainer, ContentContainer } from './styles';

import {
  Button,
  ShapeIcon,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';

interface FluxEndStepProps {
  username?: string;
  onClose?: () => any;
}

export const FluxEndStep = ({ username, onClose }: FluxEndStepProps) => {
  const firstName = username?.split(' ')[0] || '';

  return (
    <>
      <ContentContainer>
        <ShapeIcon
          variant="default"
          color="var(--color-primary)"
          name="IconCheck"
          size={64}
          strokeWidth="1.8px"
          style={{ flexShrink: 0, padding: 8 }}
        />
        <Typography
          variant="body3"
          variantColor="var(--color-secondary-50)"
          weight={700}
        >
          Olá{firstName ? `, ${firstName}!` : '!'}
        </Typography>
        <TextContainer>
          <Typography
            variant="headline8"
            variantColor="var(--color-neutral-10)"
            weight={700}
          >
            E-mail confirmado com sucesso!
          </Typography>
          <Typography variant="body3" variantColor="var(--color-neutral-50)">
            Agora você já pode aproveitar as vantagens que só a Flash te dá,
            pelo app ou plataforma. 🦩⚡
          </Typography>
        </TextContainer>
      </ContentContainer>
      <ButtonContainer>
        <Button
          variant="primary"
          size="large"
          style={{ alignSelf: 'center', width: '198px' }}
          onClick={onClose}
        >
          Começar
        </Button>
      </ButtonContainer>
    </>
  );
};
