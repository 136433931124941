import {
  IconButton,
  Tooltip,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import { Container, RowContainer } from './quick-access.styles';
import { QuickAccessItem, QuickAccess } from './components/QuickAccessItem';
import { AddQuickAccessItem } from './components/AddQuickAccessItem';
import { QuickAccessManagementModal } from './components/QuickAccessManagementModal';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorBoundary, dispatchToast, setEventTracking } from '@/utils';
import { loadQuickAccesses, saveQuickAccesses } from './quick-access.utils';
import { useTranslation } from 'react-i18next';
import { TaskWidget } from './components/TaskWidget';

export const QuickAccessSection = () => {
  const navigate = useNavigate();
  const [t] = useTranslation('translations', { keyPrefix: 'quickAccess' });
  const [isManagementOpen, setIsManagementOpen] = useState(false);
  const [quickAccesses, setQuickAccesses] = useState<QuickAccess[]>(
    loadQuickAccesses(),
  );
  const onAddQuickAccess = () => {
    setEventTracking('home_quick_access_add_access_clicked');
    setIsManagementOpen(true);
  };

  const onEditQuickAccess = () => {
    setEventTracking('home_quick_access_edit_access_clicked');
    setIsManagementOpen(true);
  };

  const onQuickAccessClick = (key: string, url: string) => {
    setEventTracking('home_quick_access_clicked', {
      access: key,
      url,
    });
    navigate(url);
  };

  const onConfirm = (quickAccesses: QuickAccess[]) => {
    try {
      const quickAccessKeys = quickAccesses.map((access) => access.key);
      setEventTracking('home_quick_access_save_accesses_click', {
        saved_access: JSON.stringify(quickAccessKeys),
      });
      saveQuickAccesses(quickAccessKeys);
      setQuickAccesses(quickAccesses);
      setIsManagementOpen(false);
      dispatchToast({
        type: 'success',
        content: 'Acessos salvos com sucesso!',
      });
    } catch (error: any) {
      error.message = 'Failed to store quick-access on local-storage';
      ErrorBoundary.captureException(error);
      dispatchToast({
        type: 'warning',
        content:
          'Não conseguimos salvar suas edições. Por favor, tente novamente.',
      });
    }
  };

  return (
    <>
      <Container>
        <RowContainer>
          <Typography variant="headline7" style={{ lineHeight: '32px' }}>
            {t('sectionTitle')}
          </Typography>
          {quickAccesses.length > 0 && (
            <Tooltip title="Editar acessos" placement="top">
              <span>
                <IconButton
                  size="small"
                  variant="line"
                  icon="IconPencil"
                  onClick={onEditQuickAccess}
                />
              </span>
            </Tooltip>
          )}
        </RowContainer>
        <RowContainer>
          {quickAccesses.length === 0 && (
            <AddQuickAccessItem onClick={onAddQuickAccess} />
          )}
          {quickAccesses.map(({ key, icon, title, product, url }) => (
            <QuickAccessItem
              key={key}
              icon={icon}
              title={title}
              product={product}
              onClick={() => onQuickAccessClick(key, url)}
            />
          ))}
        </RowContainer>
      </Container>
      <QuickAccessManagementModal
        open={isManagementOpen}
        onConfirm={onConfirm}
        onClose={() => setIsManagementOpen(false)}
      />
    </>
  );
};
