import { DefaultTheme } from '@mui/styled-engine-sc';
import styled from 'styled-components';

export type BannerSize = 'small' | 'medium' | 'large' | 'full';

interface BannerProps {
  $bannerSize: BannerSize;
}

export const Section = styled.div<BannerProps>`
  display: flex;
  width: 100%;
  gap: ${({ theme, $bannerSize }) => {
    switch ($bannerSize) {
      case 'small':
        return theme.spacings.m;
      case 'medium':
        return theme.spacings.l;
      default:
        return theme.spacings.xl5;
    }
  }};
  border-radius: ${({ theme }) => theme.borders.radius.m};
  background: ${({ theme }) => theme.colors.secondary[99]};

  padding-right: ${({ theme, $bannerSize }) =>
    $bannerSize !== 'large' && $bannerSize !== 'full' ? theme.spacings.m : 0};
`;

export const ContentContainer = styled.div<BannerProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${({ theme, $bannerSize }) =>
    $bannerSize !== 'large' && $bannerSize !== 'full'
      ? theme.spacings.xs
      : theme.spacings.xs3};
  padding: ${({ theme }) => theme.spacings.s} 0
    ${({ theme }) => theme.spacings.s} ${({ theme }) => theme.spacings.m};
`;

export const TextsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs3};
`;

export const Logo = styled.img`
  width: 173px;
  flex-shrink: 0;
`;

export const ItemsContainer = styled.div<BannerProps>`
  min-width: ${({ $bannerSize }) => ($bannerSize === 'full' ? 280 : 200)}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${({ theme }) => theme.spacings.s} 0;
  gap: ${({ theme }) => theme.spacings.xs2};
`;

export const Item = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs3};
  align-items: center;
`;

type ImageContainerProps = { $imageUrl: string } & BannerProps;

export const ImageContainer = styled.div<ImageContainerProps>`
  width: 100%;
  max-width: ${({ $bannerSize }) => getImageMaxWidth($bannerSize)}px;
  flex-shrink: 0;
  border-top-right-radius: ${({ theme }) => theme.borders.radius.s};
  border-bottom-right-radius: ${({ theme }) => theme.borders.radius.s};
  background-image: url(${({ $imageUrl }) => $imageUrl});
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
`;

const getImageMaxWidth = (size: BannerSize) => {
  switch (size) {
    case 'medium':
      return 200;
    case 'large':
      return 250;
    default:
      return 350;
  }
};
