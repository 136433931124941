type EType = 'error' | 'success' | 'warning';

interface dispatchToastParams {
  content: string;
  type: EType;
}

export const dispatchToast = (detail: dispatchToastParams) => {
  dispatchEvent(new CustomEvent('showToast', { detail }));
};
