import { Button, Icons, LinkButton } from '@flash-tecnologia/hros-web-ui-v2';
import { FooterContainer } from './styles';

type FooterProps = {
  backText?: string;
  showNextIcon?: boolean;
  closeable: boolean;
  nextText: string;
  onBack: () => void;
  onClose: () => void;
  onNext: () => void;
};

const Footer = ({
  closeable,
  onBack,
  onClose,
  onNext,
  backText,
  nextText,
  showNextIcon,
}: FooterProps) => (
  <FooterContainer multipleActions={!!backText || closeable}>
    {!!backText && (
      <LinkButton
        variant="primary"
        onClick={onBack}
        style={{ alignSelf: 'center' }}
      >
        {backText}
      </LinkButton>
    )}
    {closeable && (
      <LinkButton
        variant="primary"
        onClick={onClose}
        style={{ alignSelf: 'center' }}
      >
        Fechar
      </LinkButton>
    )}
    <Button
      size="large"
      variant="primary"
      onClick={onNext}
      sx={{ minWidth: 200 }}
    >
      {nextText} {showNextIcon && <Icons name="IconArrowRight" />}
    </Button>
  </FooterContainer>
);

export default Footer;
