import styled from 'styled-components';

interface ContainerProps {
  isDragging: boolean;
}

export const Container = styled.div<ContainerProps>`
  height: 40px;
  width: 100%;
  max-width: 420px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs4};
  padding: ${({ theme }) => theme.spacings.xs4};
  padding-right: ${({ theme }) => theme.spacings.xs2};
  background: ${({ theme, isDragging }) =>
    isDragging ? theme.colors.secondary[70] : theme.colors.neutral[100]};
  border: ${({ theme }) => theme.borders.width.xs2} solid
    ${({ theme }) => theme.colors.neutral[90]};
  border-radius: ${({ theme }) => theme.borders.radius.s};

  transition: all 0.13s linear;
  ${({ isDragging }) =>
    isDragging ? 'border: none; transform: rotate(-2deg);' : ''}

  // Workaround for transition property from drag lib
  trasition:;
`;
