import { Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled, { css, keyframes } from 'styled-components';

const slideUp = keyframes`
  from {
    transform: translateY(25%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const StyledTaskItem = styled.div<{
  index: number;
  radius?: boolean;
  border?: boolean;
  borderBottom?: boolean;
  radiusBottom?: boolean;
}>`
  padding: 12px 16px;
  background-color: ${({ theme }) => theme.colors.neutral[100]};
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: 0;
  animation: ${slideUp} 0.5s ease-out forwards;
  animation-delay: ${({ index }) => index * 0.3}s;

  ${({ radius, theme }) =>
    radius &&
    css`
      border-radius: ${theme.borders.radius.s};
    `}

  ${({ border, theme }) =>
    border &&
    css`
      border: 1px solid ${theme.colors.neutral[90]};
    `}

  ${({ borderBottom, theme }) =>
    borderBottom &&
    css`
      border-bottom: 1px solid ${theme.colors.neutral[90]};
    `}
  
  ${({ radiusBottom, theme }) =>
    radiusBottom &&
    css`
      border-bottom-left-radius: ${theme.borders.radius.s};
      border-bottom-right-radius: ${theme.borders.radius.s};
    `}
`;

export const StyledTaskItemDescription = styled(Typography)`
  font-size: 10px;
  color: ${({ theme }) => theme.colors.neutral[50]};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
`;

export const StyledTaskItemLinkWrapper = styled.a`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs5};
  cursor: pointer;
`;

export const StyledTaskItemTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
  font-size: 12px;
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledTaskItemTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: ${({ theme }) => theme.spacings.xs5};
`;

export const StyledTaskItemLink = styled(Typography)`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.secondary[50]};
`;

export const StyledTaskTagWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs4};
`;

export const StyledTypeIcon = styled(Icons)`
  stroke: ${({ theme }) => theme.colors.secondary[50]};
`;
