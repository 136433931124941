import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import Root from './root.component';
import { ErrorBoundary } from '@utils';
import { trpc, trpcClient, queryClient } from './api/client';
import { QueryClientProvider } from '@tanstack/react-query';
import { TaskHeaderIcon } from './pages/AdminHome/shared/TaskHeaderIcon';

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundaryClass: ErrorBoundary,
});

export const { bootstrap, mount, unmount } = lifecycles;

function TaskHeaderNotificationIcon() {
  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <TaskHeaderIcon />
      </QueryClientProvider>
    </trpc.Provider>
  );
}

export { TaskHeaderNotificationIcon };
