import { Dispatch } from 'react';
import { Authentication } from '@flash-hros/auth-helper';
import { EActions, IActions } from '../context/configuration/actions';
import { dispatchToast } from '../utils';

const {
  CognitoVerifyUserAttribute,
  CognitoUpdateAttributes,
  CognitoVerifyUserAttributeSubmit,
  CognitoAuthenticatedUser,
} = Authentication;

export const resendEmail = async ({
  dispatch,
}: {
  dispatch: Dispatch<IActions>;
}) => {
  try {
    dispatch({
      type: EActions.SYSTEM_LOADING_START,
      payload: 'resendEmail',
    });

    const response = await CognitoVerifyUserAttribute({
      attr: 'email',
    });

    dispatch({
      type: EActions.SYSTEM_LOADING_END,
      payload: 'resendEmail',
    });

    dispatchToast({
      type: 'success',
      content: 'E-mail de verificação enviado!',
    });

    return response;
  } catch (error: any) {
    dispatch({
      type: EActions.SYSTEM_LOADING_END,
      payload: 'resendEmail',
    });

    if (error.toString().indexOf('LimitExceededException:')) {
      dispatchToast({
        type: 'error',
        content: 'Quantidade de reenvios excedida, aguarde um pouco!',
      });
    }
  }
};
interface changeEmailProps {
  newEmail: string;
  callback?: any;
  dispatch: Dispatch<IActions>;
}

export const changeEmail = async ({
  newEmail,
  callback,
  dispatch,
}: changeEmailProps) => {
  try {
    dispatch({
      type: EActions.SYSTEM_LOADING_START,
      payload: 'changeEmail',
    });

    const response = await CognitoUpdateAttributes({
      email: newEmail,
    });

    await CognitoAuthenticatedUser({ bypassCache: true });

    if (typeof callback === 'function') callback.call();

    dispatch({
      type: EActions.SYSTEM_LOADING_END,
      payload: 'changeEmail',
    });

    dispatchToast({
      type: 'success',
      content: 'E-mail de verificação enviado!',
    });

    return response;
  } catch (err: any) {
    console.log('err', err?.message);

    dispatch({
      type: EActions.SYSTEM_LOADING_END,
      payload: 'changeEmail',
    });

    dispatchToast({
      type: 'error',
      content: 'Ops! erro ao atualizar seu e-mail, favor tentar novamente!',
    });
  }
};

interface validateCodeProps {
  attr: string;
  code: string;
  callback?: any;
  dispatch: Dispatch<IActions>;
}

export const validateCode = async ({
  attr,
  code,
  callback,
  dispatch,
}: validateCodeProps) => {
  try {
    dispatch({
      type: EActions.SYSTEM_LOADING_START,
      payload: 'validateCode',
    });

    const response = await CognitoVerifyUserAttributeSubmit({
      attr: attr,
      code: code,
    });

    await CognitoAuthenticatedUser({ bypassCache: true });

    if (typeof callback === 'function') callback.call();

    dispatch({
      type: EActions.SYSTEM_LOADING_END,
      payload: 'validateCode',
    });

    dispatchToast({
      type: 'success',
      content: 'E-mail verificado com sucesso!',
    });

    return response;
  } catch (error) {
    dispatch({
      type: EActions.SYSTEM_LOADING_END,
      payload: 'validateCode',
    });

    dispatchToast({
      type: 'error',
      content: 'Código inválido e/ou expirado, favor tentar novamente!',
    });
  }
};
