import { Modal, Stepper, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { useState } from 'react';
import {
  DescriptionText,
  TourStepContainer,
  TourStepImage,
  StepContainer,
  CloseButton,
  StyledShapeIcon,
} from './styles';
import { MODAL_STEPS, ONBOARDING_TOUR_STEPS, modalSteps } from './steps';
import { getFooterConfigForModal } from './utils';
import { useNavigate } from 'react-router-dom';
import AlertBanner from './components/AlertBanner';
import PermissionsOptions from './components/PermissionsOptions';
import Footer from './components/Footer';
import { useOnboarding } from '@context/onboarding/hook';

type OnboardingModalProps = {
  open?: boolean;
  username?: string;
  showMigrationTexts: boolean;
  onClose: () => void;
};

export const OnboardingModal = ({
  open,
  username,
  showMigrationTexts,
  onClose,
}: OnboardingModalProps) => {
  const { showAdminStepper } = useOnboarding();

  const stepNames = [
    ONBOARDING_TOUR_STEPS.QUICK_ACCESS,
    ONBOARDING_TOUR_STEPS.SETUP,
    ONBOARDING_TOUR_STEPS.COMPANIES,
    ...(showAdminStepper
      ? [ONBOARDING_TOUR_STEPS.PERMISSIONS]
      : [ONBOARDING_TOUR_STEPS.FIRST_STEPS]),
  ];
  const onboardingTourSteps = stepNames.map((step) => modalSteps[step]);

  const navigate = useNavigate();

  const [modalCurrentStep, setModalCurrentStep] = useState(0);
  const [tourCurrentStep, setTourCurrentStep] = useState(0);
  const [redirectToPermissions, setRedirectToPermissions] = useState(true);

  const closeModal = () => {
    onClose();
    setModalCurrentStep(0);
    setTourCurrentStep(0);
  };

  const handleNext = () => {
    if (modalCurrentStep === MODAL_STEPS.FINISH) {
      if (showAdminStepper) {
        navigate('/benefits/dashboard/company/employees');
        return;
      }

      closeModal();
      return;
    }

    const isLastTourStep = tourCurrentStep === onboardingTourSteps.length - 1;

    if (modalCurrentStep === MODAL_STEPS.ONBOARDING_TOUR) {
      if (!isLastTourStep) {
        setTourCurrentStep((prevCurrentStep) => prevCurrentStep + 1);
        return;
      }

      if (showAdminStepper && redirectToPermissions) {
        navigate('/settings/permissions');
        return;
      }
    }

    setModalCurrentStep((prevCurrentStep) => prevCurrentStep + 1);
  };

  const handleBack = () => {
    if (modalCurrentStep === MODAL_STEPS.WELCOME) {
      setModalCurrentStep(MODAL_STEPS.FINISH);
      return;
    }

    const isFirstTourStep = tourCurrentStep === 0;
    if (modalCurrentStep === MODAL_STEPS.ONBOARDING_TOUR && !isFirstTourStep) {
      setTourCurrentStep((prevCurrentStep) => prevCurrentStep - 1);
      return;
    }

    setModalCurrentStep((prevCurrentStep) => prevCurrentStep - 1);
  };

  const footerConfig = getFooterConfigForModal(
    modalCurrentStep,
    tourCurrentStep,
    showMigrationTexts,
    onboardingTourSteps,
    showAdminStepper ?? false,
  );

  return (
    <Modal.Root
      open={open ?? false}
      onClose={closeModal}
      header={
        <CloseButton
          size="small"
          variant="line"
          icon="IconX"
          onClick={closeModal}
        />
      }
      footer={
        <Footer
          backText={footerConfig.backText}
          closeable={footerConfig.closeable ?? false}
          onBack={handleBack}
          nextText={footerConfig.nextText}
          onClose={closeModal}
          onNext={handleNext}
          showNextIcon={footerConfig.showIcon}
        />
      }
    >
      <>
        {modalCurrentStep === MODAL_STEPS.WELCOME && (
          <StepContainer>
            <StyledShapeIcon
              name="IconMoodHappy"
              size={64}
              variant="default"
              color="var(--color-primary)"
            />
            <div>
              <Typography
                variant="body3"
                style={{ fontWeight: 700, color: 'var(--color-secondary-50)' }}
              >
                {showMigrationTexts
                  ? 'Migração concluída com sucesso'
                  : 'Sua conta foi criada com sucesso'}
                {username ? `, ${username}` : ''}!
              </Typography>
              <Typography variant="headline6">
                {showMigrationTexts
                  ? 'Você já pode aproveitar a nova plataforma Flash!'
                  : 'Agora você pode aproveitar toda a liberdade que só a Flash te dá'}
              </Typography>
            </div>
            <DescriptionText variant="body3">
              {showMigrationTexts
                ? 'Se antes a experiência já era incrível e sem burocracias, agora ficou ainda mais completa!'
                : 'Estamos super felizes em ter você aqui. Preparamos tudo para que você tenha uma experiência incrível e sem burocracias, de um jeito bem Flash.'}
            </DescriptionText>
            <DescriptionText variant="body3">
              {showMigrationTexts
                ? 'Vamos conhecer as novidades?'
                : 'Vamos conhecer um pouco a nossa plataforma?'}
            </DescriptionText>
          </StepContainer>
        )}
        {modalCurrentStep === MODAL_STEPS.ONBOARDING_TOUR && (
          <>
            <Stepper
              steps={onboardingTourSteps.map((step) => step.name)}
              activeStep={tourCurrentStep}
              setActiveStep={setTourCurrentStep}
            />
            <TourStepContainer>
              <Typography variant="headline6">
                {onboardingTourSteps[tourCurrentStep].header ??
                  onboardingTourSteps[tourCurrentStep].name}
              </Typography>
              <DescriptionText variant="body3">
                {onboardingTourSteps[tourCurrentStep].text}
              </DescriptionText>
            </TourStepContainer>
            {stepNames[tourCurrentStep] ===
            ONBOARDING_TOUR_STEPS.PERMISSIONS ? (
              <>
                <AlertBanner />
                <PermissionsOptions
                  redirectToPermissions={redirectToPermissions}
                  changeRedirectToPermission={setRedirectToPermissions}
                />
              </>
            ) : (
              <TourStepImage
                src={onboardingTourSteps[tourCurrentStep].imagePath}
                width={650}
                height={242}
              />
            )}
          </>
        )}
        {modalCurrentStep === MODAL_STEPS.FINISH && (
          <StepContainer>
            <StyledShapeIcon
              name="IconCheck"
              variant="default"
              size={64}
              color="var(--color-primary)"
            />
            <Typography variant="headline6">
              Tour concluído com sucesso, agora é com você!
            </Typography>
            <DescriptionText variant="body3">
              {showMigrationTexts
                ? 'Agora que você já conhece um pouco da plataforma, comece suas primeiras configurações!'
                : 'Agora que você já conhece um pouco das novidades da plataforma, comece suas primeiras configurações!'}
            </DescriptionText>
          </StepContainer>
        )}
      </>
    </Modal.Root>
  );
};
