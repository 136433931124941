import {
  CloseIcon,
  CompletedStateContainer,
  Container,
  Header,
  ListContainer,
  ProgressBar,
} from './styles';
import { Step, StepBlocked, StepDone } from './components/StepItem';
import {
  Button,
  LinkButton,
  ShapeIcon,
  Skeleton,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import { getFromLS, setInLS } from '@flash-tecnologia/hros-web-utility';
import { useNavigate } from 'react-router-dom';
import { useOnboardingSteps } from '@flash-hros/hocs';
import { setEventTracking } from '@utils';

type OnboardingStepsProps = {
  onClose: () => void;
};

type Step = {
  id: string;
  title: string;
  path: string;
  done: boolean;
};

export const OnboardingSteps = ({ onClose }: OnboardingStepsProps) => {
  const navigate = useNavigate();
  const { steps, onboardingProgressPercentage, completeStep, loading } =
    useOnboardingSteps();
  const userOnboardingStepsLS = getFromLS('userOnboardingSteps');

  const showOnboardingCompletedMessage =
    onboardingProgressPercentage === 100 &&
    !userOnboardingStepsLS?.onboardingCompletedMessageSeen;

  const onOnboardingCompletedMessageClose = () => {
    const userOnboarding = getFromLS('userOnboardingSteps');
    setInLS({
      key: 'userOnboardingSteps',
      value: { ...userOnboarding, onboardingCompletedMessageSeen: true },
    });
    onClose();
  };

  const hideOnboarding = () => {
    setEventTracking('home_onboarding_steps_close_clicked');
    onClose();
  };

  const onStepClick = (step: Step) => {
    setEventTracking('home_onboarding_steps_item_clicked', {
      stepId: step.id,
      text: step.title,
    });
    if (!step.done) completeStep(step.id);
    navigate(step.path);
  };

  if (loading) {
    return <Skeleton variant="rounded" height={320} />;
  }

  return (
    <Container>
      <Header>
        <ShapeIcon
          name="IconRocket"
          variant="default"
          size={32}
          color="var(--color-secondary-50)"
          style={{ flexShrink: 0 }}
        />
        <div>
          <Typography variant="body4" style={{ fontWeight: 700 }}>
            Primeiros passos
          </Typography>
          <Typography
            variant="body4"
            style={{ color: 'var(--color-neutral-50)', fontWeight: 600 }}
          >
            Comece por estas configurações.
          </Typography>
        </div>
        <CloseIcon name="IconX" size={16} onClick={hideOnboarding} />
      </Header>
      <ProgressBar variant="determinate" value={onboardingProgressPercentage} />
      {showOnboardingCompletedMessage && (
        <CompletedStateContainer>
          <ShapeIcon
            name="IconCheck"
            variant="default"
            size={48}
            color="var(--color-secondary-50)"
            style={{ flexShrink: 0 }}
          />
          <Typography variant="headline8">
            Primeiros passos completo!
          </Typography>
          <Typography
            variant="body4"
            style={{ textAlign: 'center', color: 'var(--color-neutral-50)' }}
          >
            Você configurou todos os passos básicos da sua plataforma! Agora a
            sua rotina ficará muito mais simples. Sempre que precisar, acesse os
            Primeiros Passos no ícone de foguete no topo da página!
          </Typography>
          <Button
            size="small"
            variant="secondary"
            style={{ width: '100%' }}
            onClick={onOnboardingCompletedMessageClose}
          >
            Concluir
          </Button>
          <LinkButton
            variant="secondary"
            style={{ margin: '0 auto' }}
            onClick={onOnboardingCompletedMessageClose}
          >
            Fechar primeiros passos
          </LinkButton>
        </CompletedStateContainer>
      )}

      {!showOnboardingCompletedMessage && (
        <ListContainer>
          {steps.map((step) => {
            if (step.blocked) return <StepBlocked key={step.id} step={step} />;

            if (step.done)
              return (
                <StepDone
                  key={step.id}
                  step={step}
                  onClick={() => onStepClick(step)}
                />
              );

            return (
              <Step
                key={step.id}
                step={step}
                onClick={() => onStepClick(step)}
              />
            );
          })}
        </ListContainer>
      )}
    </Container>
  );
};
