import { Icons } from '@flash-tecnologia/hros-web-ui-v2';
import { LinearProgress } from '@mui/material';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.borders.radius.s};
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  background: ${({ theme }) => theme.colors.neutral[100]};
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs3};
  padding: ${({ theme }) => `${theme.spacings.xs1} ${theme.spacings.xs}`};
`;

const CloseIcon = styled(Icons)`
  position: absolute;
  top: ${({ theme }) => theme.spacings.xs2};
  right: ${({ theme }) => theme.spacings.xs2};
  color: ${({ theme }) => theme.colors.neutral[40]};
  cursor: pointer;
`;

const ProgressBar = styled(LinearProgress)`
  height: 4px;
  flex-shrink: 0;
  background: ${({ theme }) => theme.colors.secondary[90]};
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[90]};
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs3};
  padding: ${({ theme }) => theme.spacings.xs};
  overflow: auto;
`;

const CompletedStateContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: ${({ theme }) => theme.spacings.xs2};
  padding: ${({ theme }) => theme.spacings.xs};
`;

export {
  Container,
  Header,
  CloseIcon,
  ProgressBar,
  ListContainer,
  CompletedStateContainer,
};
