import styled from 'styled-components';

export const Container = styled.div`
  width: 162px;
  height: 74px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  background: ${({ theme }) => theme.colors.secondary[99]};
  border: ${({ theme }) => theme.borders.width.xs2} dashed
    ${({ theme }) => theme.colors.secondary[70]};
  border-radius: ${({ theme }) => theme.borders.radius.s};
  gap: ${({ theme }) => theme.spacings.xs5};
`;
