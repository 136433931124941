import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const StyledSection = styled.section`
  border: solid 1px ${({ theme }) => theme.colors.neutral[90]};
  border-radius: ${({ theme }) => theme.borders.radius.s};
  background-color: ${({ theme }) => theme.colors.neutral[100]};
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledSectionHeader = styled.div`
  padding: 16px 24px;
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs4};
  align-items: center;
`;

export const StyledSectionHeaderTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
  font-size: 14px;
`;
