import styled from 'styled-components';

export const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const QuickAccessList = styled.div`
  min-height: 120px;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs3};
`;

export const MenuRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacings.xs};
`;

export const TagCheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacings.xs4};
`;
