import { Skeleton } from '@flash-tecnologia/hros-web-ui-v2';
import { StyledSkeletonList } from './styles';

export type TabContentSkeletonProps = {
  length: number;
  height: number;
};

export const TabContentSkeleton = ({
  length,
  height,
}: TabContentSkeletonProps) => {
  return (
    <StyledSkeletonList>
      {Array.from({ length }).map((_, index) => (
        <Skeleton key={index} variant="rounded" height={height} />
      ))}
    </StyledSkeletonList>
  );
};
