import { useMenuConfig } from '@/hooks/use-menu-config';
import { IconContainer, Container, TextButton } from './styled';
import {
  Icons,
  Typography,
  LinkButton,
} from '@flash-tecnologia/hros-web-ui-v2';
import { setEventTracking } from '@/utils';

const NewMenuAvailable = () => {
  const { setCurrentVersion } = useMenuConfig();
  const setNewMenu = () => {
    setCurrentVersion(2);
    setEventTracking('menu_v2_start_using_clicked');
    window.location.reload();
  };
  return (
    <Container>
      <IconContainer>
        <Icons
          name="IconSparkles"
          fill="none"
          width={16}
          height={16}
          color="var(--color-secondary-50)"
        />
      </IconContainer>
      <Typography
        variant="body3"
        variantColor="var(--color-secondary-50)"
        weight={600}
      >
        <b> Nosso menu está de cara nova!</b> Deixamos a sua experiência de
        navegação mais simples e intuitiva.{' '}
        <TextButton onClick={setNewMenu}>
          <b> Comece a usar a nova versão e deixe seu feedback!</b>
        </TextButton>
      </Typography>
    </Container>
  );
};

export default NewMenuAvailable;
