import {
  FLASH_COMPANY_CONTACT_EMAIL,
  FLASH_CONTACT_PHONE,
} from '../../shared/constants';

export default {
  translations: {
    homePage: {
      home: 'Início',
      contentTitle:
        'Você acaba de fazer uma escolha que facilita todas as outras',
      subTitle:
        'Aproveite para conhecer a plataforma e curtir a liberdade que só a Flash te dá 🦩⚡',
    },
    adminHomePage: {
      welcome: 'Olá',
      productsSectionTitle: 'Novos produtos',
      peopleProduct: {
        title: 'Gestão de Pessoas',
        description:
          'Impulsione sua empresa com uma gestão inteligente dos processos de admissão, treinamento, desenvolvimento e engajamento dos colaboradores.',
        admissionTopic: 'Admissão digital e Organograma',
        trainingTopic: 'Treinamentos e Engajamento',
        performanceTopic: 'Performance e People Analytics',
        actionText: 'Saiba mais',
      },
      needHelp: 'Precisa de ajuda?',
    },
    contractModal: {
      title: 'Contrato de Prestação de Serviços',
      textFirstLine:
        'Para finalizar sua contratação, você deve ler atentamente e aceitar as condições comerciais do Contrato de Prestação de Serviços da Flash.',
      textSecondLine: 'O uso da plataforma só é liberado após o aceite.',
      indicationText: 'Leia abaixo',
      acceptButton: 'Li e aceito',
    },
    quickAccess: {
      sectionTitle: 'Acessos rápidos',
      addQuickAccessButton: 'Adicione um atalho',
      modal: {
        title: 'Acessos rápidos',
        description:
          'Utilize o espaço de acessos rápidos para salvar fluxos mais acessados no dia-a-dia.',
        header: 'Acessos selecionados',
        addButton: 'Adicionar acessos',
        confirmButton: 'Salvar acessos',
      },
    },
    helpCentralWidget: {
      title: 'Central de ajuda',
      content: 'Seja bem à nossa central de ajuda!',
      contact: {
        title: 'Não encontrou o que precisava?',
        content: `Entre em contato pelo e-mail ${FLASH_COMPANY_CONTACT_EMAIL} ou pelo telefone ${FLASH_CONTACT_PHONE}`,
      },
    },
  },
};
