import { useContext } from 'react';
import { OnboardingContext } from '.';

export const useOnboarding = () => {
  const { setShowAdminBanner, showAdminBanner, showAdminStepper } =
    useContext(OnboardingContext);

  const closeAdminBanner = () => setShowAdminBanner(false);

  return {
    closeAdminBanner,
    showAdminBanner,
    showAdminStepper,
  };
};
