import styled from 'styled-components';
import { Typography } from '@flash-tecnologia/hros-web-ui-v2';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 378px;
  padding: ${({ theme }) => theme.spacings.xs};
  gap: ${({ theme }) => theme.spacings.xs};
  border-radius: ${({ theme }) => theme.borders.radius.s};
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacings.xs3};
`;

const Topic = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs3};
`;

const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`;

export { Container, Section, StyledTypography, Topic };
